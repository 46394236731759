import "chartist-plugin-tooltips";
import * as Chartist from "chartist";
import { Router } from "@angular/router";
import { NgBlockUI, BlockUI } from "ng-block-ui";
import { Component, OnInit } from "@angular/core";
import { ChartEvent, ChartType } from "ng-chartist";
import { APIService } from "../_services/api.service";
import { Subject } from "rxjs";
import { UserService } from "../../../src/app/_api/user.service";

export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: "app-home",
  templateUrl: "./changelog.component.html",
  styleUrls: ["./changelog.component.css"],
})
export class ChangelogComponent implements OnInit {
  productive_time: any;
  total_talk_times: any;
  @BlockUI("changelog") blockUIChangelog: NgBlockUI;
  availability_time: any;
  total_acw_time: any;
  public customCampaignType: Subject<any>;
  agent_inbound_calls_count: boolean;
  agent_outbound_calls_count: boolean;
  public breadcrumb: any;
  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true,
  };
  agent_camp_type: any;
  userType: any;
  tenantDashboard = false;
  agentDashboard = false;
  superVisorDashboard = false;
  superAdminDashboard = false;
  module_count: any;
  dataOfUser: any;
  ExpenseschartOption: any;
  ExpenseschartOption2: any;
  HandledTimechartOption: any;
  domain_data:any = '';
  agent_count: any;
  camp_count: any;
  queue_count: any;
  extension_count: any;
  job_count: any;
  mail_template_count: any;
  priority_number_count: any;
  prompt_count: any;
  sip_count: any;
  script_count: any;
  skill_count: any;
  team_count: any;
  crm_count: any;
  did_count: any;
  dnc_count: any;
  moh_count: any;
  outcome_template_count: any;
  breaks_count: any;
  email_camp_count: any;
  chat_queues_count: any;
  ivr_count: any;
  sms_queues_count: any;
  tenant_count: any;

  agent_inbound_calls: any;
  agent_outbound_calls: any;
  agent_total_calls: any;
  agent_total_hold_time: any;
  break_time_data: any;
  topAgentData: any;
  topHandledTimeAgent: any;

  topTenantsInAgents: any;
  topTenantsInCalls: any;
  TotalAgentHandledCalls: any;
  selected_Top_agent_by_number = 'This Month';
  AgentHandledCalls
  durationMap = {
    'This Week': '7 DAY',
    'This Month': '30 DAY',
    'Today': '0 DAY',
  };

  constructor(private router: Router, private apiService: APIService, private dataService: UserService,) {
    this.customCampaignType = new Subject();

  }

  ngOnInit() {
    this.breadcrumb = {
      mainlabel: "Dashboard",
      links: [
        {
          name: "Account Dashboard",
          isLink: false,
          link: "#",
        },
      ],
    };
    this.dataOfUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userType = JSON.parse(localStorage.getItem("currentUser")).account_type;
    this.showDashboard(this.userType);
    if (sessionStorage.getItem("get_agent_camp") === "OUTBOUND") {
      this.agent_inbound_calls_count = false;
      this.agent_outbound_calls_count = true;
    } else if (sessionStorage.getItem("get_agent_camp") === "INBOUND") {
      this.agent_inbound_calls_count = true;
      this.agent_outbound_calls_count = false;
    } else {
      this.agent_inbound_calls_count = true;
      this.agent_outbound_calls_count = true;
    }
  }

  // manage which view have to show
  showDashboard(userType) {
    switch (userType) {
      case "Superuser":
        this.superAdminDashboard = true;
        this.getSuperAdminModuleCount();
        this.topTenTenantInAgent();
        this.topTenTenantInCall();
        break;
      case "Agent":
        this.agentDashboard = true;
        this.getAgentModulesCount();
        this.agent_inbound_calls_count = true;
        this.agent_outbound_calls_count = true;

        this.dataService.data.subscribe((data) => {
          if (data === "OUTBOUND") {
            this.agent_camp_type = data
            sessionStorage.setItem("get_agent_camp", this.agent_camp_type)
            this.agent_inbound_calls_count = false;
            this.agent_outbound_calls_count = true;
          } else if (data === "INBOUND") {
            this.agent_camp_type = data
            sessionStorage.setItem("get_agent_camp", this.agent_camp_type)
            this.agent_inbound_calls_count = true;
            this.agent_outbound_calls_count = false;
          }
          const params = {
            cc_agent: this.dataOfUser.agent_domain_uuid,
            data_type: this.agent_camp_type
          };
          this.apiService.agentSupervisorCount(params).subscribe((response) => {
            if (response["Status"] === String(1)) {
              this.setAgentCounts(response["agent_calls"][0]);
            }
          });
        });
        break;
      case "Supervisor":
        this.agentDashboard = true;
        this.getAgentModulesCount();
        this.agent_inbound_calls_count = true;
        this.agent_outbound_calls_count = true;

        this.dataService.data.subscribe((data) => {
          if (data === "OUTBOUND") {
            this.agent_camp_type = data
            sessionStorage.setItem("get_agent_camp", this.agent_camp_type)
            this.agent_inbound_calls_count = false;
            this.agent_outbound_calls_count = true;
          } else if (data === "INBOUND") {
            this.agent_camp_type = data
            sessionStorage.setItem("get_agent_camp", this.agent_camp_type)
            this.agent_inbound_calls_count = true;
            this.agent_outbound_calls_count = false;
          }
          const params = {
            cc_agent: this.dataOfUser.agent_domain_uuid,
            data_type: this.agent_camp_type
          };
          this.apiService.agentSupervisorCount(params).subscribe((response) => {
            if (response["Status"] === String(1)) {
              this.setAgentCounts(response["agent_calls"][0]);
            }
          });
        });
        break;
      case "Quality":
        this.tenantDashboard = true;
        this.getTenantModulesCount();
        this.topTenAgentByCall();
        this.topTenAgentByHandleTime();
    }
  }

  // route to selected mmodule
  routeToselectedModule(route) {
    this.router.navigateByUrl(route);
  }

  reloadChangelog() {
    this.blockUIChangelog.start("Loading..");

    setTimeout(() => {
      this.blockUIChangelog.stop();
    }, 2500);
  }

  getSuperAdminModuleCount() {
    this.apiService.getSystemModuleCount().subscribe((response) => {
      if (response["Status"] === String(1)) {
        this.module_count = response.object;
        this.setCounts();
      }
    });
  }

  getTenantModulesCount() {
    const param = {
      domain_uuid: this.dataOfUser.account_type ==='Superuser'? this.domain_data : this.dataOfUser.domain_uuid
    }
    this.apiService.getTenantModuleCount(param).subscribe((response) => {
      if (response["Status"] === String(1)) {
        this.module_count = response['object'];
        this.setCounts();
      }
    });
  }

  getAgentModulesCount() {
    const params = {
      cc_agent: this.dataOfUser.agent_domain_uuid,
      data_type: ''
    };
    this.apiService.agentSupervisorCount(params).subscribe((response) => {
      if (response["Status"] === String(1)) {
        this.setAgentCounts(response["agent_calls"][0]);
      }
    });
  }

  setAgentCounts(agents_data) {
    this.agent_inbound_calls = agents_data.inbound_calls;
    this.agent_outbound_calls = agents_data.outbound_calls;
    this.agent_total_calls = agents_data.total_calls;
    this.agent_total_hold_time = agents_data.total_hold_time;
    this.break_time_data = agents_data.break_time;
    this.break_time_data = agents_data.break_time;
    this.availability_time = agents_data.availability_time;
    this.total_acw_time = agents_data.total_acw_time;
    this.productive_time = agents_data.productive_time;
    this.total_talk_times = agents_data.total_talk_time;
  }

  setCounts() {
    this.tenant_count = this.module_count.tenant;
    this.agent_count = this.module_count.agent;
    this.camp_count = this.module_count.camp;
    this.queue_count = this.module_count.queue;
    this.extension_count = this.module_count.extension;
    this.job_count = this.module_count.job;
    this.mail_template_count = this.module_count.mail_template;
    this.priority_number_count = this.module_count.priority_number;
    this.prompt_count = this.module_count.prompt;
    this.sip_count = this.module_count.sip;
    this.script_count = this.module_count.script;
    this.skill_count = this.module_count.skill;
    this.team_count = this.module_count.team;
    this.crm_count = this.module_count.crm;
    this.did_count = this.module_count.did;
    this.dnc_count = this.module_count.dnc;
    this.moh_count = this.module_count.moh;
    this.outcome_template_count = this.module_count.outcome_template;
    this.breaks_count = this.module_count.breaks;
    this.email_camp_count = this.module_count.email_camp;
    this.chat_queues_count = this.module_count.chat_queues;
    this.ivr_count = this.module_count.ivr;
    this.sms_queues_count = this.module_count.sms;
  }

  topTenTenantInCall() {
    const params = {
      domain_uuid: "",
      duration: "180 DAY"
    };
    this.apiService.getTopAgentByHandleTime(params).subscribe((response) => {
      if (response["Status"] === String(1)) {
        let topCallTenants = response['agent_calls'].map(
          obj => {
            return {
              name: obj.Tenant,
              value: obj.total_call_duration
            }
          }
        );
        this.topTenantsInCalls = topCallTenants;
        this.loadTopTenantInCalls();
      }
    });
  }
  loadTopTenantInCalls() {
    this.ExpenseschartOption2 = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: [],
      },
      // Add custom colors
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557", "#48d095", "#f94557"],
      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },
      // Enable drag recalculate
      // calculable: true,
      // Add series
      series: [
        {
          name: "Browsers",
          type: "pie",
          radius: ["50%", "70%"],
          center: ["50%", "57.5%"],
          itemStyle: {
            normal: {
              label: {
                show: true,
              },
              labelLine: {
                show: true,
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: "{b}" + "\n\n" + "{c} ({d}%)",
                position: "center",
                textStyle: {
                  fontSize: "17",
                  fontWeight: "500",
                },
              },
            },
          },
          data: this.topTenantsInCalls,
        },
      ],
    };
  }

  topTenTenantInAgent() {
    const params = {
      domain_uuid: "",
      duration: "180 DAY"
    };
    this.apiService.getTopAgentByCall(params).subscribe((response) => {
      if (response["Status"] === String(1)) {
        let topAgentTenants = response['agent_calls'].map(
          obj => {
            return {
              value: Number(obj.total_agent),
              name: obj.Tenant
            }
          }
        );
        this.topTenantsInAgents = topAgentTenants;
        this.loadTopTenantInAgents();
      }
    });
  }

  loadTopTenantInAgents() {
    this.ExpenseschartOption = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: [],
      },
      // Add custom colors
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557", "#48d095", "#f94557"],
      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },
      // Enable drag recalculate
      // calculable: true,
      // Add series
      series: [
        {
          name: "Browsers",
          type: "pie",
          radius: ["50%", "70%"],
          center: ["50%", "57.5%"],
          itemStyle: {
            normal: {
              label: {
                show: true,
              },
              labelLine: {
                show: true,
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: "{b}" + "\n\n" + "{c} ({d}%)",
                position: "center",
                textStyle: {
                  fontSize: "17",
                  fontWeight: "500",
                },
              },
            },
          },
          data: this.topTenantsInAgents,
        },
      ],
    };
  }
  
  topTenAgentByCall() {
    if (this.durationMap.hasOwnProperty(this.selected_Top_agent_by_number)) {
      this.AgentHandledCalls = {
        domain_uuid: this.dataOfUser.domain_uuid,
        duration: this.durationMap[this.selected_Top_agent_by_number]
      };
    }
    this.apiService.getTopAgentByCall(this.AgentHandledCalls).subscribe((response) => {
      if (response["Status"] === String(1)) {
        let topAgentByCall = response['agent_calls'].map(
          obj => {
            return {
              value: Number(obj.total_call_duration),
              name: obj.agent
            }
          }
        );
        this.topAgentData = topAgentByCall;
        this.renderTopAgentChart();
      }
    });
  }

  renderTopAgentChart() {
    this.ExpenseschartOption = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: ['']
        // data: [this.topAgentData[0]["agent"], this.topAgentData[1]["agent"]],
      },

      // Add custom colors
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557"],

      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },

      // Enable drag recalculate
      // calculable: true,

      // Add series
      series: [
        {
          name: "Browsers",
          type: "pie",
          radius: ["50%", "70%"],
          center: ["50%", "51.5%"],
          padding: "2px",
          itemStyle: {
            normal: {
              label: {
                show: true,
              },
              labelLine: {
                show: true,
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: "{b}" + "\n\n" + "{c} ({d}%)",
                position: "center",
                textStyle: {
                  fontSize: "17",
                  fontWeight: "500",
                },
              },
            },
          },
          data: this.topAgentData
          //  [
          //   {
          //     value: Number(this.topAgentData[0]["total_call_duration"]),
          //     name: this.topAgentData[0]["agent"],
          //   },
          //   {
          //     value: Number(this.topAgentData[1]["total_call_duration"]),
          //     name: this.topAgentData[1]["agent"],
          //   }
          // { value: 0, name: '' },
          // { value: 0, name: '' },
          // { value: 0, name: '' }
          // ],
        },
      ],
    };
  }

  topTenAgentByHandleTime() {
    const params = {
      domain_uuid: this.dataOfUser.domain_uuid,
      duration: "180 DAY"
    };
    this.apiService.getTopAgentByHandleTime(params).subscribe((response) => {
      if (response["Status"] === String(1)) {
        let topAgentByHandleTime = response['agent_calls'].map(
          obj => {
            return {
              value: Number(obj.total_handled_calls),
              name: obj.agent
            }
          }
        );
        this.topHandledTimeAgent = topAgentByHandleTime;
        this.renderTopHandledTimeAgent();
      }
    });
  }
  renderTopHandledTimeAgent() {
    this.HandledTimechartOption = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: ['']
        // data: [this.topHandledTimeAgent[0]["agent"], this.topHandledTimeAgent[1]["agent"]],
      },

      // Add custom colors
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557"],

      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },

      // Enable drag recalculate
      // calculable: true,

      // Add series
      series: [
        {
          name: "Browsers",
          type: "pie",
          radius: ["50%", "70%"],
          center: ["50%", "51.5%"],
          padding: "2px",
          itemStyle: {
            normal: {
              label: {
                show: true,
              },
              labelLine: {
                show: true,
              },
            },
            emphasis: {
              label: {
                show: true,
                formatter: "{b}" + "\n\n" + "{c} ({d}%)",
                position: "center",
                textStyle: {
                  fontSize: "17",
                  fontWeight: "500",
                },
              },
            },
          },
          data: this.topHandledTimeAgent
          // [
          // {
          //   value: Number(this.topHandledTimeAgent[0]["total_handled_calls"]),
          //   name: this.topHandledTimeAgent[0]["agent"],
          // },
          // {
          //   value: Number(this.topHandledTimeAgent[1]["total_handled_calls"]),
          //   name: this.topHandledTimeAgent[1]["agent"],
          // }
          // { value: 0, name: '' },
          // { value: 0, name: '' },
          // { value: 0, name: '' },
          // { value: 0, name: '' }
          // ],
        },
      ],
    };
  }

  TopAgentByHandledCalls(data) {
    this.selected_Top_agent_by_number = data;
    this.topTenAgentByCall();
  }
  
}
