import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from '../_helpers/must-match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
   styles: ['.toggle-password-icon {position: absolute; right: 10px; top: 10px; cursor: pointer;}']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup
  submitted = false;
  dataOfUser: any;
  passwordVisible = false;
  passwordHasValue = false;
  passwordHasConfirmValue = false;
  confirmPasswordVisible = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private apiservices: APIService
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      otp: ['', Validators.required]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword'),
    })
  }

  get formAlert() {
    return this.changePasswordForm.controls
  }

  resendCode() {
    const param = {
      email: sessionStorage.getItem('email_id')
    }
    this.apiservices.resendOtp(param).subscribe((response) => {
      if (response && response['Status'] === '0') {
        this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
      } else
      if (response && response['Status'] === '2') {
        this.toastr.warning(response['Msg'], 'Warning!', { progressBar: true });
      } else {
        this.toastr.success(response['Msg'], 'Success!', { progressBar: true });
      }
    })
  }

  changePassword() { }

  submit() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return
    }
    const data = {
      secret_code: this.changePasswordForm.value['otp'],
      email: sessionStorage.getItem('email_id'),
      New_password: this.changePasswordForm.value['newPassword'],
      confirm_password: this.changePasswordForm.value['confirmPassword'],
    }
    this.apiservices.otpVerify(data).subscribe((response) => {
      if (response && response['Status'] === '0') {
        this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
      } else if(response && response['Status'] === '2') {
        this.toastr.warning(response['Msg'], 'Warning!', { progressBar: true });
      } else {
        this.router.navigateByUrl('login')
        this.toastr.success(response['Msg'], 'Success!', { progressBar: true });
      }
    })
  }
togglePasswordVisibility(event: Event) {
    event.preventDefault();
    this.passwordVisible = !this.passwordVisible;
}

toggleConfirmPasswordVisibility(event: Event) {
    event.preventDefault();
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
}

checkPasswordValue(event: Event) {
    const input = event.target as HTMLInputElement;
    this.passwordHasValue = input.value.length > 0;
}

checkPasswordConfirmValue(event: Event) {
    const input = event.target as HTMLInputElement;
    this.passwordHasConfirmValue = input.value.length > 0;
}
}
