import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { APIService } from '../_services/api.service';
import { UserService } from '../_api/user.service';

import { ToastrService } from 'ngx-toastr';
import { NgxCaptchaService } from '@binssoft/ngx-captcha'
import { environment } from '../../../src/environments/environment';
import { TokenStorageService } from '../../../src/app/_services/token-storage.service';


@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    project_url = environment.DOMAIN_URL;
    loginForm: FormGroup;
    disabledLogin = false;
    submitted = false;
    loading = false;
    Login_Img_url: any;
    returnUrl: string;
    token: string | undefined;
    captchaStatus: any = null;
    defaultLoader = false;
    passwordVisible = false;
    passwordHasValue = false;
    captchaConfig: any = {
        length: 6,
        cssClass: 'custom',
        back: {
            stroke: "#ef3d39",
            solid: "#f2efd2"
        },
        font: {
            color: "#000000",
            size: "35px"
        }
    };

    constructor(
        private captchaService: NgxCaptchaService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private UserService: UserService,
        private tokenStorage: TokenStorageService,
        private apiService: APIService,
        private alertService: AlertService,
        public authService: AuthService) {
        this.token = undefined;
        this.project_url = environment.DOMAIN_URL;
        this.Login_Img_url = environment.Login_img;
    }

    ngOnInit() {
        this.captchaService.captchStatus.subscribe((status) => {
            this.captchaStatus = status;
            if (status == false) {
            } else if (status == true) {
            }
        });
        // this.recaptchaV3Service.execute('importantAction')
        // .subscribe((token) => this.handleToken(token));
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            recaptchaReactive: ['']
        });
        if (localStorage.getItem('currentUser')) {
            this.authService.doLogout();
        }
        this.passwordHasValue = this.loginForm.get('password').value.length > 0;
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    tryLogin() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.defaultLoader = true;
        this.loading = true;
        const data = {
            username: this.f.email.value,
            password: this.f.password.value,
        };
        this.apiService.login(data).subscribe((response) => {
            this.disabledLogin = true;
            if (response['Status'] === '1') {
                // get agent roles 
                this.tokenStorage.setAccountType(JSON.stringify(response));
                this.tokenStorage.setAuthToken(response['auth_token']);
                this.tokenStorage.setRefreshToken(response['refresh_token']);
                localStorage.setItem('profile_url', response['profile_pic'])
                localStorage.setItem('profile_image', this.project_url + response['profile_pic']);
                if (response['account_type'] == "Superuser" || response['account_type'] == "Admin" || response['account_type'] == "Supervisor" || response['account_type'] == "Tenant") {
                    this.submitted = false;
                    return;
                }
                if (response['account_type'] == "Agent" || response['account_type'] == "Supervisor") {
                    sessionStorage.setItem('selected_agent_role_id', response['agent_role_uuid'])
                    this.apiService.getAgentRole(response['agent_role_uuid']).subscribe((res) => {
                        const roleData = res['GetAgentRoles'];
                        const data_check = roleData[0];
                        sessionStorage.setItem('superAdminData', (data_check.role_datas));
                        this.UserService.userLoginType.next(res['GetAgentRoles']);
                    });
                }
                // domainuuid
                response = { ...response, email: this.f.email.value };
                this.setUserInStorage(response);
                localStorage.removeItem('currentLayoutStyle');
                let returnUrl = '/dashboard/sales';
                if (this.returnUrl) {
                    returnUrl = this.returnUrl;
                }
                setTimeout(() => {
                    this.router.navigate([returnUrl]);
                    this.disabledLogin = false;

                }, 2000)
            } else  if (response['Status'] === '2') {
                this.loading = false;
                this.defaultLoader = false;
                this.submitted = false;
                // this.alertService.error(response['Msg']);
                setTimeout(() => {
                    this.defaultLoader = false;
                    this.disabledLogin = false;

                    this.toastr.warning(response['Msg'], 'Warning!', { progressBar: true });
                }, 1000)
            } else {
                this.loading = false;
                this.submitted = false;
                // this.alertService.error(response['Msg']);
                setTimeout(() => {
                    this.defaultLoader = false;
                    this.disabledLogin = false;

                    this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
                }, 1000)
            }
        });
    }

    setUserInStorage(res) {
        if (res.account_type === 'Tenant') {
            localStorage.setItem('currentUser', JSON.stringify(res));
            sessionStorage.removeItem('show_superuser_login_icon');
        } else if (res.account_type === 'Superuser') {
            localStorage.setItem('superuser_user', JSON.stringify(res));
            localStorage.setItem('currentUser', JSON.stringify(res));
            sessionStorage.removeItem('show_superuser_login_icon');
        } else if (res.account_type === 'Admin') {
            localStorage.setItem('superuser_user', JSON.stringify(res));
            localStorage.setItem('currentUser', JSON.stringify(res));
            sessionStorage.removeItem('show_superuser_login_icon');
        } else {
            localStorage.setItem('currentUser', JSON.stringify(res));
            sessionStorage.removeItem('show_superuser_login_icon');
        }
    }
    togglePasswordVisibility(event: Event) {
        event.preventDefault();
        this.passwordVisible = !this.passwordVisible;
    }

    checkPasswordValue(event: Event) {
        const input = event.target as HTMLInputElement;
        this.passwordHasValue = input === document.activeElement && input.value.length > 0;
    }
}
