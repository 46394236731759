import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-full-layout-navbar',
  templateUrl: './full-layout-navbar.component.html',
  styleUrls: ['./full-layout-navbar.component.css']
})
export class FullLayoutNavbarComponent implements OnInit {
  project_name = environment.PROJECT_NAME;
  Project_name:any;
  Vertical_Img_url:any;
  constructor(
    @Inject(DOCUMENT) private document: Document) { 
      this.Project_name = environment.PROJECT_NAME;
      this.Vertical_Img_url = environment.Vertical_Progect_logo;
    }

  ngOnInit() {
  }

  toggleNavbar(e) {
    const navbar = this.document.getElementById('navbar-mobile');
    if (navbar.classList.contains('show')) {
      navbar.classList.remove('show');
    } else {
      navbar.classList.add('show');
    }
  }
}
