import { Injectable } from "@angular/core";
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from "firebase/app";

@Injectable()
export class AuthService {
  constructor(public afAuth: AngularFireAuth) {}
  
  doAnonymousLogin() {
    return this.afAuth.auth.signInAnonymously();
  }

  doLogout() {
    sessionStorage.clear();
    localStorage.removeItem("PreviousRecordingData");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("superuser_user");
    window.location.href = "/login";
  }
}
