import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavbarService } from "../../../_services/navbar.service";
import { ThemeSettingsService } from "../../settings/theme-settings.service";
import { MenuSettingsService } from "../../settings/menu-settings.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AuthService } from "../../../../app/_services/auth.service";
import { Router } from "@angular/router";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
  PerfectScrollbarDirective,
} from "ngx-perfect-scrollbar";
import { UserService } from "../../../../../src/app/_api/user.service";
import { AppConstants } from "../../../../app/_helpers/app.constants";
import { APIService } from "../../../_services/api.service";
import { ToastrService } from "ngx-toastr";
import { UtilsService } from "../../../_helpers/utils";
import { environment } from '../../../../environments/environment';

declare var $: any;
declare var Telemo: any;
declare var window: any;

const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
};

const docWithBrowsersExitFunctions = document as Document & {
  mozCancelFullScreen(): Promise<void>;
  webkitExitFullscreen(): Promise<void>;
  msExitFullscreen(): Promise<void>;
};
@Component({
  selector: "app-header-vertical",
  templateUrl: "./vertical.component.html",
  styleUrls: ["./vertical.component.scss"],
})
export class VerticalComponent implements OnInit, AfterViewInit {
  Vertical_Img_url: any;
  project_name = environment.PROJECT_NAME;
  profileNameShow = true;
  profileImage: any;
  profileExist = false;
  campaignChhange = "";
  getTimeData: any;
  insideTm: any;
  outsideTm: any;
  // isDisabled=true;
  updateButtonShow: boolean = true;
  camp_list_showing: boolean = true;
  currentBreak = "Available";
  avialability_check: any;
  public customCampaignType: Subject<any>;

  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  public _themeSettingsConfig: any;
  private _menuSettingsConfig: any;
  public selectedHeaderNavBarClass: string;
  public selectedNavBarHeaderClass: string;
  public currentUser: any;
  public isHeaderSearchOpen: any;
  isMobile = false;
  showNavbar = false;
  public maximize: any;
  public search: any;
  public internationalization: any;
  public notification: any;
  public email: any;
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: false };
  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true })
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild("available_option") viewSelect: ElementRef;
  // new declaration
  dataOfUser: any;
  returnUrl: string;
  showBackToSuperUserLoginIcon = false;
  currentDate: any;
  showSettingIcon = false;
  allBreaksData: any;
  noDataFound = false;
  showBreakOption = false;
  showCampaignsOptions = false;
  agentMediaDropdown: any;
  no_camp_list_showing: boolean = false;
  stopCheckingBreak: any;
  allCampaignsData: any;
  agent_assigned_campaigns: any;
  soicalQueueData: any;
  available_list: any;
  isAgentReady = false;
  set_outbound_camp_uuid: any;
  set_inbound_camp_uuid: any;
  set_blended_camp_uuid: any;
  selected_camp_type: any;
  selected_campaign_uuid: any;
  selectedInbound: number = Number(0);
  selectedOutbound: number = Number(0);
  selectedBlended: number = Number(0);
  seconds: number;
  minutes: number;
  hours: number;
  display: any = "00:00:00";
  interval: any = 0;
  time: any = 0;
  timer;
  readyInterval: any = 0;
  Readytime: any = 0;
  waitingInterval: any = 0;
  waitingtime: any = 0;
  BreakInterval: any = 0;
  Breaktime: any = 0;
  chatInterval: any = 0;
  chattime: any = 0;
  smsInterval: any = 0;
  smstime: any = 0;
  ringingInterval: any = 0;
  ringingtime: any = 0;
  emailInterval: any = 0;
  emailtime: any = 0;
  facebookInterval: any = 0;
  facebooktime: any = 0;
  whatsappInterval: any = 0;
  whatsapptime: any = 0;
  twitterInterval: any = 0;
  twittertime: any = 0;
  show_Resume_call = false;
  show_pause_call = false;
  checkRealtimeAgentStatus: any;
  checkTenantCurrentAuthToken: any;
  session_id:any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _renderer: Renderer2,
    private navbarService: NavbarService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
    public authService: AuthService,
    private router: Router,
    private elementRef: ElementRef,
    private apiService: APIService,
    private toastr: ToastrService,
    // private websocket: WebsocketService,
    // private comp: ChangelogComponent,
    private dataService: UserService,
    private utils: UtilsService
  ) {
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
    // customEvent = new Subject();
    // customStatusChange = new Subject();
    // agentReadyStateSubject = new Subject();
    this.customCampaignType = new Subject();   
     this.Vertical_Img_url = environment.Vertical_Progect_logo;
  }

  // logout() {
  //   this.authService.doLogout();
  // }

  ngOnInit() {
    if (this.currentBreak === "Available") {
      this.startStatusTimer();
    } else {
    }
    this.available_list = [{ name: "available" }, { name: "acw" }];
    this.agentMediaDropdown = [
      { name: "Chat" },
      { name: "Email" },
      { name: "SMS" },
      { name: "Whatsapp" },
      { name: "Facebook" },
      { name: "Twitter" },
    ];
    this.dataOfUser = JSON.parse(localStorage.getItem("currentUser"));
    this.isMobile = window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH;
    if (!this.isMobile) {
      this.showNavbar = true;
    }
    if (localStorage.getItem("currentUser")) {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    }
    this.session_id = this.currentUser.session_id;

    this.regesterAgentOnShip();

    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
        this.refreshView();
      });
    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._menuSettingsConfig = config;
      });
    this.maximize = this._themeSettingsConfig.headerIcons.maximize;
    this.search = this._themeSettingsConfig.headerIcons.search;
    this.internationalization =
      this._themeSettingsConfig.headerIcons.internationalization;
    this.notification = this._themeSettingsConfig.headerIcons.notification;
    this.email = this._themeSettingsConfig.headerIcons.email;

    this.showBackToSuperUserLogin();
    setInterval(() => {
      this.currentDate = new Date();
    }, 1);

    this.getAvailableCampaign();
    this.manageBreakOptions();
    // this.currentBreak = 'adb';
    // set agent sip status on dialer
    $(".agent_registered").hide();
    $(".agent_unregistered").show();
    $("#agent_is_registered").css("background", "#f10c0c");
    // this.startTimer();

    // collabse the left menu
    this.toggleFixMenu("e");

    if (
      this.dataOfUser.account_type == "Quality" 
    ) {
      this.checkAgentCurrentStatus();
    }
    const { account_type, agent_name, email } = this.dataOfUser;
    if (account_type == "Agent") this.register_on_socket(agent_name, email, account_type);
    // profile pic start 
    if (this.dataOfUser.account_type == "Quality") {
      this.dataService.currentMessage.subscribe((message) => {
        if (message != '') {
          this.profileImage = message;
          this.profileExist = true;
        } else {
          this.profileExist = false;
          this.profileImage = '';
        }
      })
      if (localStorage.getItem("profile_url") === '' || localStorage.getItem("profile_url") === '<nil>') {
        this.profileExist = false;
      } else {
        this.profileExist = true;
        this.profileImage = localStorage.getItem("profile_image");
      }
    }
    // profile end


  }


  selectDropValue(data) {
    const telemoSdk = new Telemo();
    telemoSdk.unRegisterUser();
    sessionStorage.setItem("media_status", data);
    $("#ready_call_timer").css({ display: "none" });
    $("#waiting_call_timer").css({ display: "none" });

    // this.ReadyResetTimer();
    this.BreakResetTimer();
    this.show_pause_call = false;
    this.show_Resume_call = true;
  }
  SelectResumeClick() {
    sessionStorage.removeItem("media_status");
    this.regesterAgentOnShip();
    $("#sms_call_timer").css({ display: "none" });
    $("#chat_call_timer").css({ display: "none" });
    $("#email_call_timer").css({ display: "none" });
    $("#facebook_call_timer").css({ display: "none" });
    $("#whatsapp_call_timer").css({ display: "none" });
    $("#twitter_call_timer").css({ display: "none" });
    this.show_pause_call = true;
    this.show_Resume_call = false;
    this.chatReadyResetTimer();
    this.emailResetTimer();

    if (sessionStorage.getItem("camp_type") === "INBOUND") {
      $("#waiting_call_timer").css({ display: "block" });
      $("#ready_call_timer").css({ display: "none" });
    } else if (sessionStorage.getItem("camp_type") === "OUTBOUND") {
      $("#waiting_call_timer").css({ display: "none" });
      $("#ready_call_timer").css({ display: "block" });
    }
  }

  //  Chat time counter
  chatStatusTimer() {
    this.chatInterval = setInterval(() => {
      if (this.chattime === 0) {
        this.chattime++;
      } else {
        this.chattime++;
      }
      var getOption = <HTMLInputElement>document.getElementById("chatTimerHead");
      getOption.innerHTML = this.transform(this.chattime);
    }, 1000);
  }
  chatReadyResetTimer() {
    clearInterval(this.chatInterval);
    this.chatInterval = setTimeout(() => {
      this.chattime = 0;
    }, 0);
  }

  //  Email time counter
  emailStatusTimer() {
    this.emailInterval = setInterval(() => {
      if (this.emailtime === 0) {
        this.emailtime++;
      } else {
        this.emailtime++;
      }
      var getOption = <HTMLInputElement>document.getElementById("emailTimerHead");
      getOption.innerHTML = this.transform(this.emailtime);
    }, 1000);
  }
  emailResetTimer() {
    clearInterval(this.emailInterval);
    this.emailInterval = setTimeout(() => {
      this.emailtime = 0;
    }, 0);
  }


  // start Break time counter
  BreakStatusTimer() {
    this.BreakInterval = setInterval(() => {
      if (this.Breaktime === 0) {
        this.Breaktime++;
      } else {
        this.Breaktime++;
      }
      var getOption = <HTMLInputElement>document.getElementById("breakHead");
      getOption.innerHTML = this.transform(this.Breaktime);
    }, 1000);
  }
  BreakResetTimer() {
    clearInterval(this.BreakInterval);
    this.BreakInterval = setTimeout(() => {
      this.Breaktime = 0;
    }, 0);
  }

  // Available timer
  startStatusTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.transform(this.time);
    }, 1000);
  }

  checkAgentCurrentStatus() {
    this.checkRealtimeAgentStatus = setInterval(() => {
      this.apiService
        .checkAgentCurrentStatus(this.dataOfUser.agent_domain_uuid)
        .subscribe((response) => {
          if (response["v_call_center_agents"]["agent_current_status"] === "Logged Out") {
            this.signout();
            clearInterval(this.checkRealtimeAgentStatus);
          }
        });
    }, 4000);
  }

  checkTenantActiveSession() {
    this.checkTenantCurrentAuthToken = setInterval(() => {
      const param = {
        domain_name: this.dataOfUser.domain_name,
      };
      this.apiService.getTenantCurrentAuthToken(param).subscribe((response) => {
        if (response["Account_details"][0]["auth_token"] == this.dataOfUser.auth_token) {
        } else {
          this.signout();
          this.toastr.error("Session Expired!", "Error!", { progressBar: true });
          clearInterval(this.checkTenantCurrentAuthToken);
        }
      });
    }, 4000);
  }

  resetTimer() {
    clearInterval(this.interval);
    this.interval = setTimeout(() => {
      this.time = 0;
    }, 0);
  }
  transform(value: number): string {
    var sec_num = value;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    return (
      ("00" + hours).slice(-2) +
      ":" +
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + seconds).slice(-2)
    );
  }

  getAvailableCampaign() {
    // registration and avaialable camapign
    this.dataOfUser = JSON.parse(localStorage.getItem("currentUser"));
    if (
      this.dataOfUser.account_type == "Agent" ||
      this.dataOfUser.account_type == "Supervisor"
    ) {
      const agent_data = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
      };
      this.apiService.checkAgentRequestedBreak(agent_data).subscribe((response) => {
        if (response["BreakRequestStatus"] === "approved") {
        }
        if (
          response["BreakRequestStatus"] === "waiting" &&
          response["AgentCurrentStatus"] === "Available"
        ) {
          this.currentBreak = response["BreakRequestStatus"];
        } else if (
          response["BreakRequestStatus"] === "approved" &&
          response["AgentCurrentStatus"] != "Logged Out"
        ) {
        } else if (
          response["BreakRequestStatus"] === "approved" &&
          response["AgentCurrentStatus"] === "Available"
        ) {
          this.currentBreak = response["AgentCurrentStatus"];
        } else if (
          response["BreakRequestStatus"] === "disapproved" &&
          response["AgentCurrentStatus"] === "Available"
        ) {
          this.currentBreak = response["AgentCurrentStatus"];
        } else if (response["Status"] == 2) {
          this.currentBreak = this.dataOfUser.agent_status;
        }
      });
    }
  }

  manageBreakOptions() {
    this.showBreakOption = false;
    switch (this.dataOfUser.account_type) {
      case "Quality":
        this.showSettingIcon = false;
        this.showBreakOption = true;
        this.showCampaignsOptions = true;
        this.show_pause_call = true;
        this.getCampaignList();
        this.loadAvailableBreaks();
        break;
    }
  }

  loadAvailableBreaks() {
    // get break list
    const get_break_param = {
      domain_uuid: this.dataOfUser.domain_uuid,
      limit: "",
      offset: "",
      search:'',
    };
    this.apiService.getBreakList(get_break_param).subscribe((response) => {
      if (response["BreakList"] === undefined) {
        this.noDataFound = true;
      } else {
        this.noDataFound = false;
        this.allBreaksData = response["BreakList"];
        for (let i = 0; i <= this.allBreaksData.length; i++) { }
      }
    });
  }

  registerConnect(){
    this.regesterAgentOnShip();
  }
  // new code start
  regesterAgentOnShip() {
    if (this.dataOfUser.extension_enabled == true) {
      const SipUrl = environment.ProxyUrl;
      const SipPort = environment.ProxyPort;
      const domain = "wss://" + SipUrl + ":" + SipPort;
      const telemoSdk = new Telemo();
      const options = {
        username: this.dataOfUser.extension,
        password: this.dataOfUser.extension_password,
        display_name: this.dataOfUser.extension,
        trace_sip: "true",
        turnServerURL: "turn:numb.viagenie.ca",
        turnUsername: "stun@google.com",
        turnPassword: "q1w2e3r4",
        subDomain: this.dataOfUser.domain_name,
      };
      telemoSdk.login(options, domain);
      setTimeout(() => {
        this.getAgentReadyToCall();
      }, 3000);
    } else {
      this.toastr.error(
        "Your extension is disabled please contact admin to enable it.",
        "Error!",
        { progressBar: true }
      );
    }
  }

  getAgentReadyToCall() {
    if (sessionStorage.getItem("isResistered") === "true") {
      const params = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        break_name: "Available",
        domain_uuid: this.dataOfUser.domain_uuid,
        session_id:this.session_id,
      };
      this.apiService.requestBreak(params).subscribe((response) => {
        if (response["Status"] === String(1)) {
          this.isAgentReady = true;
          this.dataService.agentReadyStateSubject.next('Available');

          $(".agent_registered").show();
          $(".agent_unregistered").hide();
          $("#agent_is_registered").css("background", "#92c560");
          // this.toastr.success("Agent Ready", "Success!", { progressBar: true });
        } else {
          this.isAgentReady = false;
          $(".agent_registered").hide();
          $(".agent_unregistered").show();
          $("#agent_is_registered").css("background", "#f10c0c");
          this.toastr.error("Agent Not Ready", "Error!", { progressBar: true });
        }
      });
    }
  }

  getCampaignList() {
    const params = {
      domain_uuid: this.dataOfUser.domain_uuid,
      agent_uuid: this.dataOfUser.agent_domain_uuid,
    };
    this.apiService.getAgentWithCampaign(params).subscribe((response) => {
      if (response["Status"] === String(1)) {
        this.allCampaignsData = response["OutboundCamps"];
        this.agent_assigned_campaigns = [];
        if (this.allCampaignsData.length === 0) {
          this.no_camp_list_showing = true;
          this.updateButtonShow = false;
          this.camp_list_showing = false;
        } else {
          this.no_camp_list_showing = false;
          this.camp_list_showing = true;
          this.updateButtonShow = true;
        }
        this.getAgentCampain();
      } else {
        this.allCampaignsData = "";
      }
    });
    this.soicalQueueData = "";
  }

  avialbleSt(data) {
    if (this.dataOfUser.extension_enabled == true) {
      this.setAvailabeState(data);
    } else {
      this.toastr.error(
        "Your extension is disabled please contact admin to enable it.",
        "Error!",
        { progressBar: true }
      );
    }
  }

  setAvailabeState(data) {
    // this.ReadyResetTimer();
    this.BreakResetTimer();
    if (data === "Available") {
      if (sessionStorage.getItem("camp_type") === "INBOUND") {
        $("#outgoingResetTimer").css({ display: "none" });
        $("#break_call_timer").css({ display: "none" });
        $("#sms_call_timer").css({ display: "none" });
        $("#chat_call_timer").css({ display: "none" });
        $("#email_call_timer").css({ display: "none" });
        $("#facebook_call_timer").css({ display: "none" });
        $("#whatsapp_call_timer").css({ display: "none" });
        $("#twitter_call_timer").css({ display: "none" });
      } else {
        $("#break_call_timer").css({ display: "none" });
        $("#sms_call_timer").css({ display: "none" });
        $("#chat_call_timer").css({ display: "none" });
        $("#email_call_timer").css({ display: "none" });
        $("#facebook_call_timer").css({ display: "none" });
        $("#whatsapp_call_timer").css({ display: "none" });
        $("#twitter_call_timer").css({ display: "none" });
      }
    }
    const SipUrl = environment.ProxyUrl;
      const SipPort = environment.ProxyPort;
      const domain = "wss://" + SipUrl + ":" + SipPort;
    const telemoSdk = new Telemo();
    const options = {
      username: this.dataOfUser.extension,
      password: this.dataOfUser.extension_password,
      display_name: this.dataOfUser.extension,
      trace_sip: "true",
      turnServerURL: "turn:numb.viagenie.ca",
      turnUsername: "stun@google.com",
      turnPassword: "q1w2e3r4",
      subDomain: this.dataOfUser.domain_name,
    };
    telemoSdk.login(options, domain);
    setTimeout(() => {
      var getOption = <HTMLInputElement>document.getElementById("avialability_check");
      getOption.innerText = data;
      getOption.style.color = "#92c560";
    }, 1000);

    const params = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      break_name: "Available",
      domain_uuid: this.dataOfUser.domain_uuid,
      session_id:this.session_id,
    };
    this.apiService.requestBreak(params).subscribe((response) => {
      if (response["Status"] === String(1)) {
        this.dataService.agentReadyStateSubject.next("Available");
      } else {
      }
    });
    this.resetTimer();
    this.startStatusTimer();
  }
  applyBreak(appliedBreak) {

    let break_data = appliedBreak;
    this.stopBreakApiInterval();
    const requestDta = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      break_name: appliedBreak,
      domain_uuid: this.dataOfUser.domain_uuid,
      session_id:this.session_id,
    };
    this.apiService.requestBreak(requestDta).subscribe((response) => {
      if (response["Status"] == 1) {
        this.toastr.success(response["Msg"], "Success!", { progressBar: true });
        this.stopCheckingBreak = setInterval(() => {
          const requestDta = {
            agent_uuid: this.dataOfUser.agent_domain_uuid,
          };
          this.apiService.checkAgentRequestedBreak(requestDta).subscribe((response) => {
            if (
              response["BreakRequestStatus"] === "approved" &&
              response["AgentCurrentStatus"] != "Available"
            ) {
              this.stopBreakApiInterval();
              this.toastr.success(
                "Your requested break is approved by supervisor",
                "Success!",
                { progressBar: true }
              );
              var getOption = <HTMLInputElement>(
                document.getElementById("avialability_check")
              );
              this.dataService.agentReadyStateSubject.next(appliedBreak);

              getOption.innerText = appliedBreak;
              sessionStorage.setItem("set_value", appliedBreak);
              getOption.style.color = "red";
              this.resetTimer();
              this.startStatusTimer();
              const telemoSdk = new Telemo();
              telemoSdk.unRegisterUser();
              this.BreakResetTimer();
              this.BreakStatusTimer();
    
              if (
                sessionStorage.getItem("camp_uuid_for_call_tab") === "undefined" ||
                sessionStorage.getItem("camp_uuid_for_call_tab") === null
              ) {
                this.router.navigateByUrl("sessions/lock");
              }
            } else if (response["BreakRequestStatus"] === "disapproved") {
              this.currentBreak = "Available";
              this.stopBreakApiInterval();
              this.toastr.error("Break deny by your supervisor", "Error!", {
                progressBar: true,
              });
            }
          });
        }, 5000);
      } else {
        this.toastr.error(response["Msg"], "Error!", { progressBar: true });
      }
    });
  }

  stopBreakApiInterval() {
    clearInterval(this.stopCheckingBreak);
  }

  loadRouteRandom() { }

  // update agent campaign start from here 


  getAgentCampain(): void { }

  // update agent campaign ends from here 

  signout() {
    if (
      this.dataOfUser.account_type == "Quality"
    ) {
      // force logout start
      const update_agent_status_data = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        status: "Logged Out",
      };
      this.apiService.forceLogout(update_agent_status_data).subscribe((response) => {
      });
    sessionStorage.clear();

      // sessionStorage.removeItem('page_number');	
      // sessionStorage.removeItem('totalCount');	
      // sessionStorage.removeItem('totalSize');	
      const requestDta = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        break_name: "Logged Out",
        domain_uuid: this.dataOfUser.domain_uuid,
        session_id:this.session_id,
      };
      this.apiService.requestBreak(requestDta).subscribe((response) => {
        if (response["Status"] == 1) {
          this.authService.doLogout();
          this.toastr.success(response["Msg"], "Success!", { progressBar: true });
        } else {
          this.toastr.error(response["Msg"], "Error!", { progressBar: true });
        }
      });
    } else if (
      this.dataOfUser.account_type == "Tenant" ||
      this.dataOfUser.account_type == "Superuser" ||
      this.dataOfUser.account_type == "Admin"
    ) {
      this.authService.doLogout();
    }

  }

  showBackToSuperUserLogin() {
    if (
      JSON.parse(localStorage.getItem("currentUser")) !== null &&
      JSON.parse(localStorage.getItem("currentUser")) !== null &&
      sessionStorage.getItem("show_superuser_login_icon") === "true"
    ) {
      this.showBackToSuperUserLoginIcon = true;
    }
  }

  getAvatar() {
    return (this.dataOfUser.agent_name || this.dataOfUser.domain_name).charAt(0);
  }

  backToSuperadmin() {
    localStorage.removeItem("currentLayoutStyle");
    sessionStorage.removeItem("show_superuser_login_icon");
    this.setUserInStorage(JSON.parse(localStorage.getItem("superuser_user")));
    let returnUrl = "/dashboard/";
    window.location.href = "/dashboard";
    if (this.returnUrl) {
      returnUrl = this.returnUrl;
    }
    this.router.navigate([returnUrl]);
  }

  setUserInStorage(res) {
    localStorage.setItem("currentUser", JSON.stringify(res));
  }

  ngAfterViewInit(): void {
    this.refreshView();
    // this.campaignChhange = sessionStorage.getItem('get_data_camp_uuid');

  }

  refreshView() {
    const iconElement = document.getElementsByClassName("toggle-icon");
    const menuColorElement = document.getElementsByClassName("main-menu");
    const navigationElement = document.getElementsByClassName("main-menu");
    const navbarElement = document.getElementsByClassName("header-navbar");
    const themeColorElement = document.getElementsByClassName("header-navbar");
    const element = document.getElementsByClassName("navbar-header");
    const boxelement = document.getElementById("customizer");
    if (iconElement) {
      if (
        this._themeSettingsConfig.colorTheme === "semi-light" ||
        this._themeSettingsConfig.colorTheme === "light"
      ) {
        this._renderer.removeClass(iconElement.item(0), "white");
        this._renderer.addClass(iconElement.item(0), "blue-grey");
        this._renderer.addClass(iconElement.item(0), "darken-3");
      } else if (
        this._themeSettingsConfig.colorTheme === "semi-dark" ||
        this._themeSettingsConfig.colorTheme === "dark"
      ) {
        this._renderer.addClass(iconElement.item(0), "white");
        this._renderer.removeClass(iconElement.item(0), "blue-grey");
        this._renderer.removeClass(iconElement.item(0), "darken-3");
      }
    }

    if (this._themeSettingsConfig.colorTheme === "semi-light") {
      this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
      this.selectedNavBarHeaderClass = "";
    } else if (this._themeSettingsConfig.colorTheme === "semi-dark") {
      this.selectedNavBarHeaderClass = this._themeSettingsConfig.color;
      this.selectedHeaderNavBarClass = "";
    } else if (this._themeSettingsConfig.colorTheme === "dark") {
      this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
      this.selectedNavBarHeaderClass = "";
    } else if (this._themeSettingsConfig.colorTheme === "light") {
      this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
      this.selectedNavBarHeaderClass = this._themeSettingsConfig.color;
    }
    if (menuColorElement) {
      if (this._themeSettingsConfig.menuColor === "menu-dark") {
        this._renderer.removeClass(menuColorElement.item(0), "menu-light");
        this._renderer.addClass(menuColorElement.item(0), "menu-dark");
      } else if (this._themeSettingsConfig.menuColor === "menu-light") {
        this._renderer.removeClass(menuColorElement.item(0), "menu-dark");
        this._renderer.addClass(menuColorElement.item(0), "menu-light");
      }
    }

    if (themeColorElement) {
      if (this._themeSettingsConfig.colorTheme === "semi-light") {
        this._renderer.removeClass(themeColorElement.item(0), "navbar-semi-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-light");
      } else if (this._themeSettingsConfig.colorTheme === "semi-dark") {
        this._renderer.removeClass(themeColorElement.item(0), "navbar-semi-light");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-light");
      } else if (this._themeSettingsConfig.colorTheme === "dark") {
        this._renderer.removeClass(themeColorElement.item(0), "navbar-semi-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-semi-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-light");
      } else if (this._themeSettingsConfig.colorTheme === "light") {
        this._renderer.removeClass(themeColorElement.item(0), "navbar-semi-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-dark");
        this._renderer.removeClass(themeColorElement.item(0), "navbar-semi-light");
      }
    }

    if (navigationElement) {
      if (this._themeSettingsConfig.navigation === "menu-native-scroll") {
        this._renderer.addClass(navigationElement.item(0), "menu-native-scroll");
      } else if (this._themeSettingsConfig.navigation === "menu-icon-right") {
        this._renderer.addClass(navigationElement.item(0), "menu-icon-right");
      } else if (this._themeSettingsConfig.navigation === "menu-bordered") {
        this._renderer.addClass(navigationElement.item(0), "menu-bordered");
      } else if (this._themeSettingsConfig.navigation === "menu-flipped") {
        this._renderer.addClass(document.body, "menu-flipped");
      } else if (this._themeSettingsConfig.navigation === "menu-collapsible") {
        this._renderer.addClass(navigationElement.item(0), "menu-collapsible");
      } else if (this._themeSettingsConfig.navigation === "menu-static") {
        this._renderer.addClass(navigationElement.item(0), "menu-static");
      }
    }

    if (navbarElement) {
      if (this._themeSettingsConfig.menu === "navbar-static-top") {
        this._renderer.addClass(navbarElement.item(0), "navbar-static-top");
        this._renderer.addClass(navigationElement.item(0), "menu-static");
      }
    }

    if (navbarElement) {
      if (this._themeSettingsConfig.menu === "semi-light") {
        this._renderer.addClass(navbarElement.item(0), "navbar-semi-light bg-gradient-x-grey-blue");
      } else if (this._themeSettingsConfig.menu === "semi-dark") {
        this._renderer.addClass(navbarElement.item(0), "navbar-semi-dark");
      } else if (this._themeSettingsConfig.menu === "dark") {
        this._renderer.addClass(navbarElement.item(0), "navbar-dark");
      } else if (this._themeSettingsConfig.menu === "light") {
        this._renderer.addClass(navbarElement.item(0), "navbar-light");
      }
    }
  }

  resetOpenMenu() {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu) {
        menu["isOpen"] = false;
        menu["isActive"] = false;
        menu["hover"] = false;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          menu["isOpen"] = false;
          menu["isActive"] = false;
          menu["hover"] = false;
          menu.submenu.items[j]["isOpen"] = false;
        }
      }
    }
  }

  setOpenInNavbar(value) {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu && menu.page === this.router.url) {
        menu["isOpen"] = value;
        menu["isActive"] = value;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          if (menu.submenu.items[j].page === this.router.url) {
            menu["isOpen"] = value;
            menu["isActive"] = value;
            menu.submenu.items[j]["isOpen"] = value;
            menu.submenu.items[j]["isActive"] = value;
            break;
          }
        }
      }
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    this.navbarService.setMouseInRegion(true);
    const navBar = this.document.getElementById("navbar-header");
    const mainMenu = this.document.getElementById("main-menu");

    // check if the left aside menu is fixed
    if (!navBar.classList.contains("expanded")) {
      this._renderer.addClass(navBar, "expanded");
      this._renderer.addClass(mainMenu, "expanded");
      this.resetOpenMenu();
      this.setOpenInNavbar(true);
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(event) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    const _self = this;
    const navBar = this.document.getElementById("navbar-header");
    const mainMenu = this.document.getElementById("main-menu");
    if (navBar && navBar.classList.contains("expanded")) {
      this.insideTm = setTimeout(() => {
        if (!_self.navbarService.isMouseInRegion()) {
          this._renderer.removeClass(navBar, "expanded");
          this._renderer.removeClass(mainMenu, "expanded");
          this.resetOpenMenu();
          this.setOpenInNavbar(false);
        }
      }, 100);
    }
    this.navbarService.setMouseInRegion(false);
  }

  // example to update badge value dynamically from another component
  updateMenuBadgeValue() {
    for (let i = 0; i < this._menuSettingsConfig.items.length; i++) {
      if (this._menuSettingsConfig.items[i].badge) {
        this._menuSettingsConfig.items[i].badge.value = 19;
      }
    }
    this._menuSettingsService.config = this._menuSettingsConfig;
  }

  handleCollapseOfMenu(element) {
    if (
      element.classList &&
      element.classList.contains("has-sub") &&
      element.classList.contains("open")
    ) {
      element.classList.remove("open");
      element.classList.remove("hover");
      element.classList.add("menu-collapsed-open");
    }
  }

  handleExpandOfMenu(element) {
    if (
      element.classList &&
      element.classList.contains("has-sub") &&
      element.classList.contains("menu-collapsed-open")
    ) {
      element.classList.remove("menu-collapsed-open");
      element.classList.add("open");
      element.classList.add("hover");
    }
  }

  toggleMenu(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const parent = target.parentElement;
    if (parent && parent.classList.contains("has-sub")) {
      this.openSubMenuUsingParent(parent);
    } else {
      const parentOfParent = parent.parentElement;
      this.openSubMenuUsingParent(parentOfParent);
    }
  }

  openSubMenuUsingParent(parent) {
    if (
      parent.classList &&
      parent.classList.contains("has-sub") &&
      !parent.classList.contains("open")
    ) {
      parent.classList.add("open");
    } else if (
      parent.classList &&
      parent.classList.contains("has-sub") &&
      parent.classList.contains("open")
    ) {
      parent.classList.remove("open");
    }
  }

  toggleFullScreen() {
    const toggleIcon = document.getElementsByClassName("ficon");

    if (toggleIcon.item(0).classList.contains("ft-maximize")) {
      this.openfullscreen();
      this._renderer.removeClass(toggleIcon.item(0), "ft-maximize");
      this._renderer.addClass(toggleIcon.item(0), "ft-minimize");
    } else if (toggleIcon.item(0).classList.contains("ft-minimize")) {
      this.closefullscreen();
      this._renderer.addClass(toggleIcon.item(0), "ft-maximize");
      this._renderer.removeClass(toggleIcon.item(0), "ft-minimize");
    }
  }

  openfullscreen() {
    // Trigger fullscreen
    // tslint:disable-next-line: no-shadowed-variable
    const docElmWithBrowsersFullScreenFunctions =
      document.documentElement as HTMLElement & {
        mozRequestFullScreen(): Promise<void>;
        webkitRequestFullscreen(): Promise<void>;
        msRequestFullscreen(): Promise<void>;
      };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
      /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
      /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
  }

  closefullscreen() {
    // tslint:disable-next-line: no-shadowed-variable
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
      /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
      /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
  }

  toggleFixMenu(e) {
    if (this.document.body.classList.contains("menu-expanded")) {
      // show the left aside menu
      this.navbarService.setFixedMenu(false);
      this.document.body.classList.remove("menu-expanded");
      this.document.body.classList.add("menu-collapsed");
      // Change switch icon
      this._themeSettingsConfig.menu = "collapse";
    } else {
      this.navbarService.setFixedMenu(true);
      this.document.body.classList.remove("menu-collapsed");
      this.document.body.classList.add("menu-expanded");
      // Change switch icon
      this._themeSettingsConfig.menu = "expand";
    }
    const navBar = this.document.getElementById("navbar-header");
    const mainMenu = this.document.getElementById("main-menu");
    this._renderer.addClass(navBar, "expanded");
    this._renderer.addClass(mainMenu, "expanded");
    setTimeout(() => {
      AppConstants.fireRefreshEventOnWindow();
    }, 300);
  }

  toggleNavigation(e) {
    const sidenav = document.getElementById("sidenav-overlay");
    const sidebarLeft =
      document.getElementById("sidebar-left") ||
      document.getElementById("email-app-menu") ||
      document.getElementById("sidebar-todo");
    const contentOverlay = document.getElementById("content-overlay");

    if (
      this.document.body.classList.contains("menu-open") &&
      (this.router.url === "/todos" ||
        this.router.url === "/contacts" ||
        this.router.url === "/email" ||
        this.router.url === "/chats")
    ) {
      this.document.body.classList.remove("menu-open");
      this._renderer.removeClass(sidenav, "d-block");
      this._renderer.removeClass(contentOverlay, "show");
      this.document.body.classList.add("menu-close");
      this._renderer.addClass(sidenav, "d-none");
      this.showNavbar = false;
    } else if (this.document.body.classList.contains("menu-open")) {
      this.document.body.classList.remove("menu-open");
      this._renderer.removeClass(sidenav, "d-block");
      this.document.body.classList.add("menu-close");
      this._renderer.addClass(sidenav, "d-none");
      this.showNavbar = false;
    } else {
      this._renderer.removeClass(sidenav, "d-none");
      this.document.body.classList.remove("menu-close");
      this.document.body.classList.add("menu-open");
      this._renderer.addClass(sidenav, "d-block");
      this.showNavbar = false;
    }

    if (sidebarLeft) {
      this._renderer.removeClass(sidebarLeft, "show");
      this._renderer.removeClass(contentOverlay, "show");
    }
  }

  toggleNavbar(e) {
    if (this.showNavbar) {
      this.showNavbar = false;
    } else {
      this.showNavbar = true;
    }
  }

  public clickSearch() {
    if (this.isHeaderSearchOpen) {
      this.isHeaderSearchOpen = false;
    } else {
      this.isHeaderSearchOpen = true;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH) {
      this.isMobile = true;
      this.showNavbar = false;
    } else {
      this.isMobile = false;
      this.showNavbar = true;
    }
  }

  echoTest() {
    const telemoSdk = new Telemo();
    const agentUUID = this.dataOfUser.agent_domain_uuid;
    telemoSdk.audioCall(9196, "", "", "", agentUUID);
    sessionStorage.setItem("caller_number", $("#telNumber").val());
    $("#terminateCall").show();
    $("#makeAudioCall").hide();
    $("#makeAudioCallBtnContainer").hide();
    $("#dailPadConTainer").hide();
    $("#outgoingResetTimer").hide();
    $("#resetTime").hide();
    $("#outgoingResetTimer").hide();
    $("#dailPadConTainer").hide();
  }

  /**
   * Create a socket to send and receive real time message
   */
  register_on_socket(name: string, user_id: string, role: string) {
    const short_name: string = this.utils.short_name_finder(name);
    const space_seprated_name: Array<string> = name.split(/\s+/);
    const parmas = {
      role,
      user_id,
      short_name,
      emailId: space_seprated_name[0],
      roomName: "no_rooom",
      limit: 25,
      last_name: space_seprated_name[space_seprated_name.length - 1],
    };
    // this.websocket.emit("join", parmas);
  }
}
function myStopFunction() {
  throw new Error("Function not implemented.");
}

function datacheck(datacheck: any) {
  throw new Error("Function not implemented.");
}

function dataRemove() {
  throw new Error("Function not implemented.");
}
