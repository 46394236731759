import "chartist-plugin-tooltips";
import * as Chartist from "chartist";
import { Router } from "@angular/router";
import { NgBlockUI, BlockUI } from "ng-block-ui";
import { Component, OnInit } from "@angular/core";
import { ChartEvent, ChartType } from "ng-chartist";
import { APIService } from "../_services/api.service";
import { Subject, Subscription, timer } from "rxjs";
import { UserService } from "../../../src/app/_api/user.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from "rxjs/operators";
declare var Telemo: any;
declare const $: any;

export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: "app-qa-dashboard",
  templateUrl: "./qa-dashboard.component.html",
  styleUrls: ["./qa-dashboard.component.css"],
})
export class QaDashboardComponent implements OnInit {
  productive_time: any;
  total_talk_times: any;
  @BlockUI("changelog") blockUIChangelog: NgBlockUI;
  availability_time: any;
  total_acw_time: any;
  public customCampaignType: Subject<any>;
  agent_inbound_calls_count: boolean;
  agent_outbound_calls_count: boolean;
  public breadcrumb: any;
  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true,
  };
  agent_camp_type: any;
  userType: any;
  tenantDashboard = false;
  agentDashboard = false;
  superVisorDashboard = false;
  superAdminDashboard = false;
  isTopPieLoading: boolean = false;
  module_count: any;
  dataOfUser: any;
  ExpenseschartOption: any;
  ExpenseschartOption2: any;
  HandledTimechartOption: any;
  domain_data:any = '';
  agent_count: any;
  camp_count: any;
  queue_count: any;
  extension_count: any;
  job_count: any;
  mail_template_count: any;
  priority_number_count: any;
  prompt_count: any;
  sip_count: any;
  script_count: any;
  skill_count: any;
  team_count: any;
  crm_count: any;
  did_count: any;
  dnc_count: any;
  moh_count: any;
  outcome_template_count: any;
  breaks_count: any;
  email_camp_count: any;
  chat_queues_count: any;
  ivr_count: any;
  sms_queues_count: any;
  tenant_count: any;

  agent_inbound_calls: any;
  agent_outbound_calls: any;
  agent_total_calls: any;
  agent_total_hold_time: any;
  break_time_data: any;
  topAgentData: any;
  topHandledTimeAgent: any;

  topTenantsInAgents: any;
  topTenantsInCalls: any;
  TotalAgentHandledCalls: any;
  selected_Top_agent_by_number = 'This Month';
  AgentHandledCalls;
  qualityDashboardData: any;
  durationType = "Month";
  durationMap = {
    'This Week': '7 DAY',
    'This Month': '30 DAY',
    'Today': '0 DAY',
  };
  selected_channel = 'voice';
  selected_channel_value = 'Voice'
  selected_channel_value_Audits='Voice'
  selected_channel_Pending='voice'

  /*** Live Call ****/  

  allAgentData: any;
  allExtensionData: any;
  teams_details: any;
  allQueueData: any;
  allCampaignsData: any;
  allEmailCampaignList: any = [];
  allInboundCampaigns: any;
  allOutboundCampaigns: any;
  allBlendedCampaigns: any;
  allSocialCampaigns: any;
  allWebchatCampaign: any;

  noInboundCampaignFound = false;
  noOutboundCampaignFound = false;
  noBlendedCampaignFound = false;
  noSocialCampaignFound = false;
  noWebChatCampaignFound = false;
  noEmailCampaignFound  = false;
  noCampaignFound = false;
  selectedCampaignUuid = '';
  filterLiveCallsForm: FormGroup;
  filterQueueCallsForm: FormGroup;
  liveAgentForm: FormGroup;
  filterLiveChatForm: FormGroup;
  filterQueueChatsForm: FormGroup;
  medialiveAgentForm: FormGroup;
  dashboardCampaignForm: FormGroup;
  dashboardOptionsFilterForm: FormGroup;

  running_live_call_datas: any;
  LiveCallParam: any;
  isLoader: boolean = false;
  isPieLoading: boolean = false;
  extensions = true;
  teams = true;
  campains = true;
  call_queues = true;
  direction = true;
  live_calls_count: any;
  subscription !: Subscription;
  currentDuration: any = "5000";

  constructor(private router: Router, private apiService: APIService, private dataService: UserService, private fb: FormBuilder, private toastr: ToastrService,  private modalService: NgbModal,) {
    this.customCampaignType = new Subject();
  }

  ngOnInit() {
    this.breadcrumb = {
      mainlabel: "Dashboard",
      links: [
        {
          name: "Account Dashboard",
          isLink: false,
          link: "#",
        },
      ],
    };
    this.dataOfUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userType = JSON.parse(localStorage.getItem("currentUser")).account_type;

    if (sessionStorage.getItem('live_dashboard_last_selected_campaign') === null) {
      this.selectedCampaignUuid = '';
    }
    else {
      this.selectedCampaignUuid = sessionStorage.getItem('live_dashboard_last_selected_campaign');
    }

    this.optionsSelectionsForm();
    this.showDashboard(this.userType);
    this.loadCampaignList();
    this.liveCallsShort(); 
  }

  // manage which view have to show
  showDashboard(userType) {
    switch (userType) {
      case "Quality":
        this.tenantDashboard = true;
        this.getTenantModulesCount();
        this.topTenAgentByCall();
        this.auditPendingApiCall();
    }
  }

  getTenantModulesCount() {
    this.isLoader = true;
    const param = {
      "domain_uuid": this.dataOfUser.domain_uuid,
      "start_date": this.durationType,
      "end_date":""
  }
    this.apiService.qualityAgentDashboard(param).subscribe((response) => {
      this.isLoader = false;
      if (response["Status"] === String(1)) {
        this.qualityDashboardData = response['quality_agent_dashboard'];
      }
    });
  }

  optionsSelectionsForm() {
    this.dashboardOptionsFilterForm = this.fb.group({
      extensions: [''],
      teams: [''],
      campains: [''],
      call_queues: [''],
      direction: [''],
    });
  }

  onDurationChange() {
    this.getTenantModulesCount();
    this.topTenAgentByCall();
    this.auditPendingApiCall();
  }
  
  topTenAgentByCall() {
    this.isTopPieLoading = true;
    // this.isPieLoading = true;
      this.AgentHandledCalls = {
        "domain_uuid": this.dataOfUser.domain_uuid,
        "start_date": this.durationType,
        "channel": this.selected_channel
    }
    this.apiService.topTenAgentByRating(this.AgentHandledCalls).subscribe((response) => {
      // this.isPieLoading = false;
      this.isTopPieLoading = false;
      if (response["Status"] === String(1)) {
        let topAgentByCall = response['quality_agent_rating'].map(
          obj => {
            return {
              value: Number(obj.average_rating),
              name: obj.agent_name
            }
          }
        );
        this.topAgentData = topAgentByCall;
        this.renderTopAgentChart();
      }
    });
  }

  renderTopAgentChart() {
    this.ExpenseschartOption = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: ['']
        // data: [this.topAgentData[0]["agent"], this.topAgentData[1]["agent"]],
      },  

      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
    },

      // Add custom colors
      // color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557"],
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557","#30914D","#5499C7","#1643b5","#91ad6d","#00FFFF"],
      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },

      // Add series
      series: [
        {
          name: "Browsers",
          type: 'pie',
          radius: ["50%", "70%"],
          center: ["50%", "51.5%"],

          label: {
            show: true,
            formatter(param) {
              // correct the percentage
              return param.name;
            }
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          },
          data: this.topAgentData
        },
      ],
    };
  }

  auditPendingApiCall() {
    this.isPieLoading = true;
    const params = {
      domain_uuid: this.dataOfUser.domain_uuid,
      "start_date": this.durationType,
      "channel": this.selected_channel_Pending
  }
    this.apiService.auditPendingByChannel(params).subscribe((response) => {
      this.isPieLoading = false;
      if (response["Status"] === String(1)) {
        let topAgentByHandleTime = response['pending_audit_by_channel'].map(
          obj => {
            return {
              value: Number(obj.pending_count),
              name: obj.channel
            }
          }
        );
        this.topHandledTimeAgent = topAgentByHandleTime;
        this.renderTopHandledTimeAgent();
      }
    });
  }

  renderTopHandledTimeAgent() {
    this.HandledTimechartOption = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: ['']
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
    },
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557"],
      toolbox: {
        show: true,
        orient: "vertical",
      },
      series: [
        {
          name: "Browsers",
          type: "pie",
          radius: ["50%", "70%"],
          center: ["50%", "51.5%"],
          padding: "2px",
          itemStyle: {
            normal: {
              label: {
                show: true,
              },
              labelLine: {
                show: true,
              },
            },
          },
          data: this.topHandledTimeAgent
        },
      ],
    };
  }

  TopAgentByHandledCalls(data) {
    this.selected_channel = data;
    if(data=='voice'){
      this.selected_channel_value = 'Voice'
    }else if(data=='whatsapp'){
      this.selected_channel_value = 'Whatsapp'
    }else if(data=='email'){
      this.selected_channel_value = 'Email'
    }
    this.topTenAgentByCall();
  }
  AuditsPendingByChannels(data) {
    this.selected_channel_Pending = data;
    if(data=='voice'){
      this.selected_channel_value_Audits = 'Voice'
    }else if(data=='whatsapp'){
      this.selected_channel_value_Audits = 'Whatsapp'
    }else if(data=='email'){
      this.selected_channel_value_Audits = 'Email'
    }
    this.auditPendingApiCall();
  }
/****************************************************************** Live call Functions ********************************************************************/

  getAgentList() {
    const requestDta = {
      domain_uuid: this.dataOfUser.domain_uuid,
      limit: '',
      search: '',
      offset: '',
    }
    this.apiService.showAgentList(requestDta).subscribe((response) => {
      if (response['Status'] === String(1)) {
        this.allAgentData = response['AgentList'];
        this.allAgentData = this.allAgentData.map(agent_name => ({
      ...agent_name,
      agent_name: this.capitalizeFirstLetter(agent_name.agent_name)
    }));
      } else {
       
        
      }
    });
  }

  capitalizeFirstLetter(agent_name: string): string {
    if (!agent_name) return agent_name;
    return agent_name.replace(/\b\w/g, char => char.toUpperCase());
  }
  
  getExtensionList() {
    const requestDta = {
      domain_uuid: this.dataOfUser.domain_uuid,
      limit: "",
      offset: "",
      search: '',
    }
    this.apiService.getExtensionList(requestDta).subscribe((response: any) => {
      if (response[response.length - 1]["Status"] == 0) {
      } else {
        this.allExtensionData = response.slice(0, response.length - 1);
      }
    });
  }

  getTeamList() {
    const requestDta = {
      domain_uuid: this.dataOfUser.domain_uuid,
      limit: "",
      offset: "",
      search: ""
    }
    this.apiService.showTeams(requestDta).subscribe((response) => {
      if (response['Status'] == 1) { 
        this.teams_details = response['supervisor_team_detail'];
      } else {
   
      }
    });
  }

  getQueuesList() {
    const requestDta = {
      domain_uuid: this.dataOfUser.domain_uuid,
    }
    this.apiService.listQueues(requestDta).subscribe((response) => {
      if (response[0]['Status'] == 0) {
      } else {
        this.allQueueData = response;
      }
    }); 
  }

  onCampaignChange(data) {
    const allcampins = this.allCampaignsData;
    sessionStorage.setItem('live_dashboard_last_selected_campaign', data);
    allcampins.forEach((element) => {
      if (element.camp_uuid == data) {
        sessionStorage.setItem('live_dashboard_camp_type', element.camp_type)
        this.getQueuesList();
        this.liveRuningCallData();
      }
      })
  }

  loadCampaignList() {
    const inboundPayload = {
      domain_uuid: this.dataOfUser.domain_uuid,
      camp_type: 'INBOUND'
    };
    this.apiService.getCampaignListByType(inboundPayload).subscribe((response) => {
      if (response['CampaignList'] === undefined) {
        this.noInboundCampaignFound = false;
      } else {
        this.noInboundCampaignFound = true;
        this.allInboundCampaigns = response['CampaignList'];
      }
    });
    // fetch outbound campains
    const outboundPayload = {
      domain_uuid: this.dataOfUser.domain_uuid,
      camp_type: 'OUTBOUND'
    };
    this.apiService.getCampaignListByType(outboundPayload).subscribe((response) => {
      if (response['CampaignList'] === undefined) {
        this.noOutboundCampaignFound = false;
      } else {
        this.noOutboundCampaignFound = true;
        this.allOutboundCampaigns = response['CampaignList'];
      }
    });
    // fetch blended campaigns
    const blendedPayload = {
      domain_uuid: this.dataOfUser.domain_uuid,
      camp_type: 'BLENDED'
    };
    this.apiService.getCampaignListByType(blendedPayload).subscribe((response) => {
      if (response['CampaignList'] === undefined) {
        this.noBlendedCampaignFound = false;
      } else {
        this.noBlendedCampaignFound = true;
        this.allBlendedCampaigns = response['CampaignList'];
      }
    });

    // Web chat campains
    this.apiService.getWebchatcampaign(this.dataOfUser.domain_uuid).subscribe((response) => {

      if (response['Status'] === String(1)) {
        this.noWebChatCampaignFound = true;
        this.allWebchatCampaign = response['GetWebChatCampaign'];
      } else {
        this.noWebChatCampaignFound = false;
      }
    });


    // fetch social sampains
    this.apiService.getsocialcampaign(this.dataOfUser.domain_uuid).subscribe((response) => {
      if (response['Status'] == 1) {
        this.noSocialCampaignFound = true;
        this.allSocialCampaigns = response['SocialCampaigns'].filter(campaign => campaign.status === 'true');
        this.noSocialCampaignFound = this.allSocialCampaigns.length > 0;
      } else {
        this.noSocialCampaignFound = false;
        this.allSocialCampaigns = []
      }
    });


    const requestData = {
      domain_uuid: this.dataOfUser.domain_uuid
    };

    this.apiService.getAllCampaignList(requestData).subscribe((campaignList: any)=> {
      if (campaignList && campaignList.all_social_campaigns) {
        this.allEmailCampaignList = campaignList.all_social_campaigns.filter(list => list.mapped_with === "email");
        this.noEmailCampaignFound = this.allEmailCampaignList.length > 0;
      } else {
        this.noEmailCampaignFound = false;
      }
    })
    this.apiService.getCampaignList(requestData).subscribe((response) => {
      if (response['CampaignList'] === undefined) {
        this.noCampaignFound = true;
      } else {
        this.noCampaignFound = false;
        this.allCampaignsData = response['CampaignList'];
        if (sessionStorage.getItem('live_dashboard_last_selected_campaign') === null || sessionStorage.getItem('live_dashboard_last_selected_campaign') === undefined) {
        } else {   
          this.searchLiveCalls('');
          this.liveRuningCallData();
        }
      }
    });
  }

  liveRuningCallData() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const prams = {
      domain_uuid: this.dataOfUser.domain_uuid,
      agent_uuid: this.filterLiveCallsForm.value['agent'],
      extention: this.filterLiveCallsForm.value['extension'],
      agent_status: this.filterLiveCallsForm.value['agents_status'],
      team_uuid: this.filterLiveCallsForm.value['team'],
      camp_uuid: this.selectedCampaignUuid,
      grp_id: '',
      queue_uuid: this.filterLiveCallsForm.value['queue'],
      direction: this.filterLiveCallsForm.value['call_direction'],
      dialer_type: this.filterLiveCallsForm.value['dialer_type'],
      call_duration: this.filterLiveCallsForm.value['call_duration']
    }
    this.subscription = timer(0, this.currentDuration).pipe(
      switchMap(() =>
    this.apiService.getLiveCalls(prams))).subscribe((response) => {
      if (response['Status'] == 1 && response['LiveCalls'].length != 0) {
        this.running_live_call_datas = response['LiveCalls'];
      }
      else if (response['Status'] == 0 && response['LiveCalls'].length == 0) {
        this.running_live_call_datas = [];
        this.live_calls_count = 0;
      } else {
        this.live_calls_count = 0;
        this.running_live_call_datas = [];
      }
    });
  }

  searchLiveCalls(LivecallButton) {

    if (this.selectedCampaignUuid != "" && this.selectedCampaignUuid != "0" ){ 
      const prams = {
        domain_uuid: this.dataOfUser.domain_uuid,
        agent_uuid: this.filterLiveCallsForm.value['agent'],
        extention: this.filterLiveCallsForm.value['extension'],
        agent_status: this.filterLiveCallsForm.value['agents_status'],
        team_uuid: this.filterLiveCallsForm.value['team'],
        camp_uuid: this.selectedCampaignUuid,
        grp_id: '',
        queue_uuid: this.filterLiveCallsForm.value['queue'],
        direction: this.filterLiveCallsForm.value['call_direction'],
        dialer_type: this.filterLiveCallsForm.value['dialer_type'],
        call_duration: this.filterLiveCallsForm.value['call_duration']
      }
      this.LiveCallParam = prams
   
      this.apiService.getLiveCalls(prams).subscribe((response) => {
        if (response['Status'] == 1) {
          if(LivecallButton == 'LiveCallButtonClick'){
            this.toastr.success(response['Msg'], 'Success!', { progressBar: true });
          }
          this.running_live_call_datas = response['LiveCalls'];
        }
        else if (response['Status'] == 2) {
          if(LivecallButton == 'LiveCallButtonClick'){
              this.toastr.warning('No data found', 'Warning!', { progressBar: true });
          }
          this.running_live_call_datas = [];

        } else {
           if(LivecallButton == 'LiveCallButtonClick'){
            this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
           }
        }
      });
    }else{
      this.PleaseSelectCampaign()
    }
  }

  PleaseSelectCampaign(){
    this.toastr.warning('Please Select Campaign', 'Warning!', { progressBar: true });
  }

  liveCallsShort() {
    this.filterLiveCallsForm = this.fb.group({
      agent: [''],
      extension: [''],
      agents_status: [''],
      team: [''],
      list: [''],
      queue: [''],
      call_direction: [''],
      dialer_type: [''],
      call_duration: [''],
      campaignData: ['']
    });
  
    this.getAgentList();
    this.getExtensionList();
    this.getTeamList();
    this.getQueuesList();
  }

  RefreshLiveCalls() {
    if (this.selectedCampaignUuid != "") {
      const prams = {
        domain_uuid: this.dataOfUser.domain_uuid,
        agent_uuid: this.filterLiveCallsForm.value['agent'],
        extention: this.filterLiveCallsForm.value['extension'],
        agent_status: this.filterLiveCallsForm.value['agents_status'],
        team_uuid: this.filterLiveCallsForm.value['team'],
        camp_uuid: this.selectedCampaignUuid,
        grp_id: '',
        queue_uuid: this.filterLiveCallsForm.value['queue'],
        direction: this.filterLiveCallsForm.value['call_direction'],
        dialer_type: this.filterLiveCallsForm.value['dialer_type'],
        call_duration: this.filterLiveCallsForm.value['call_duration']
      }
      this.apiService.getLiveCalls(prams).subscribe((response) => {
        if(response['Status'] == 1){
          this.toastr.success(response['Msg'], 'Success!', { progressBar: true });
          this.running_live_call_datas = response['LiveCalls'];
        }
         else if (response['Status'] == 2) {
           this.toastr.warning('No data found', 'Warning!', { progressBar: true });
          this.running_live_call_datas = [];

        } else {
          this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
          this.running_live_call_datas = [];
        }
      });
    } else {
      this.PleaseSelectCampaign();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
    this.subscription.unsubscribe();
    }
  }

  openModuleSelectionConfig(openModuleSelectionModal) {
    this.modalService.open(openModuleSelectionModal, { scrollable: true, centered: true, windowClass: 'animated fadeInDown' });
  }

  formOpen() {
    $('#advanceSearchForm').slideToggle();
  }

  monitorCall(agent_extention, index) {
    const telemoSdk = new Telemo();
    sessionStorage.setItem("caller_number", agent_extention);
    telemoSdk.monitorCall('*33' + agent_extention, '', '', '', '');
    sessionStorage.setItem('monitor_ext', '*33');
    $("#makeAudioCall").hide();
    $("#terminateCall").show();
  }
  bargeCall() {
    const telemoSdk = new Telemo();
    telemoSdk.callBarge('3');
  }
  coachCall() {
    const telemoSdk = new Telemo();
    telemoSdk.callCoach('2');
  }

}
