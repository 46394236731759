import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

// set and get auth token in session storage
  public setAuthToken(data) {
    sessionStorage.removeItem('authKey');
     sessionStorage.setItem('authKey', data)
  }

  public getAuthToken() {
    const token =  sessionStorage.getItem('authKey');
    return token;
  }

// set and get refresh token in session storage

  public setRefreshToken(data) {
    sessionStorage.removeItem('refreshKey');
    sessionStorage.setItem('refreshKey', data)
  }

  public getRefreshToken() {
   const token =  sessionStorage.getItem('refreshKey');
    return token;
  }

  // set and get account type in session storage

  public setAccountType(data) {
    sessionStorage.removeItem('account_type');
    sessionStorage.setItem('account_type', data)
  }

  public getAccountType() {
   const account =  JSON.parse(sessionStorage.getItem('account_type'));
    return account;
  }
}
