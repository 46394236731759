export const environment = {
  production: false,
  PROJECT_NAME:'Telemo',
  TELEPHONY_URL:'https://multiverse-media.callibry.com',
  RECORDING_URL:'https://multiverse-media.callibry.com',
  API_URL: 'https://multiverse-app.callibry.com/accounting/',
  AGENT_QUEUE_URL: 'https://multiverse-app.callibry.com/call-center/',
  SOCIAL_MEDIA_URL: 'https://multiverse-app.callibry.com/facebook/',
  TWITTER_BASE_URL: 'https://multiverse-app.callibry.com:40707/',
  WHATSAPP_BASE_URL : 'https://multiverse-app.callibry.com/whatsapp/',
  FACEBOOK_BASE_URL : 'https://multiverse-app.callibry.com/facebook/',
  VOICE_BASE_URL : 'https://multiverse-app.callibry.com:30101/',
  TELEGRAM_BASE_URL : 'https://multiverse-app.callibry.com/telegram/',
  VIBER_BASE_URL : 'https://multiverse-app.callibry.com/viber/',
  DOMAIN_URL :  'https://multiverse-app.callibry.com',
  Footer_WebSite_Name :'StarTele Tech Pvt. Ltd.',
  Login_img: 'assets/images/logo/logo-dark.png',
  Vertical_Progect_logo: 'assets/images/logo/logo.png',
  Footer_WebSite_url: 'https://www.startelelogic.com/',
  AgentSessionSocketUrl : 'wss://multiverse-app.callibry.com:9889',
  RecordingtelephonyURl : 'https://multiverse-media.callibry.com',
  wsEndpoint : 'wss://multiverse-proxy.callibry.com/main',
  ProxyUrl: 'vodacc-primary-telephony.telemo.io',
  ProxyPort:'7443',
  SocketIOChatUrl : 'https://multiverse-app.callibry.com:9000/',
  LOG: (e) => console.log(e),
  ERROR: (e) => console.error(e),
  firebase: {
    apiKey: 'AIzaSyAvHtu5rrqKSZQg75R2MYqlNgGdMKy2YR4',
    projectId: 'modern-admin-8453e',
    authDomain: 'modern-admin-8453e.firebaseapp.com',
    appId: '1:300550201463:web:96a271cc309ac5aea86672'
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU'
};