
export interface MenuItem {

  dynamic_menu_item: any;
  title: string;
  icon: string;
  page: string;
  show: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {

  // super admin menu
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  // tenant menu
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
  TENANT_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
  AGENT_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
  SUPERVISOR_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
}

export const MenuSettingsConfig: MenuConfig = {

  horizontal_menu: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Account Dashboard',
              icon: 'la-home',
              page: '/dashboard',
              show: 'true',
            },
            {
              title: 'Live Dashboard',
              icon: 'icon-bar-chart',
              page: '/tenant/live-dashboard',
              show: 'true',
            },
            {
              title: 'Wallboard',
              icon: 'icon-paper-plane',
              page: '/tenant/wallboard',
              show: 'true',
            },
          ]
        }
      },
      {
        title: 'Recording',
        icon: 'la-headphones',
        page: '/Channel/recordings',
        show: 'true',
      },
      {
        title: 'Audits',
        icon: 'la-balance-scale',
        page: '/qualitymanager/agent-performance',
        show: 'true',
      },
    ]
  },
  vertical_menu: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'QA Dashboard',
              icon: 'la-home',
              page: '/dashboard',
              show: 'true',
            },
            {
              title: 'Account Dashboard',
              icon: 'la-home',
              page: '/dashboard',
              show: 'false',
            },
            {
              title: 'Live Dashboard',
              icon: 'icon-bar-chart',
              page: '/tenant/live-dashboard',
              show: 'false',
            },
            {
              title: 'Wallboard',
              icon: 'icon-paper-plane',
              page: '/tenant/wallboard',
              show: 'false',
            },
          ]
        }
      },
      {
        title: 'Channel Audits',
        icon: 'fa fa-tv',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Voice',
              icon: 'la-home',
              page: '/Channel/recordings',
              show: 'true',
            },
            {
              title: 'Email',
              icon: 'icon-paper-plane',
              page: '/Channel/email',
              show: 'true',
            },
            {
              title: 'WhatsApp',
              icon: 'icon-bar-chart',
              page: '/Channel/whatsApp',
              show: 'true',
            },
            {
              title: 'Viber',
              icon: 'icon-bar-chart',
              page: '/Channel/viber',
              show: 'true',
            },
            {
              title: 'Telegram',
              icon: 'icon-bar-chart',
              page: '/Channel/telegram',
              show: 'true',
            },
            {
              title: 'Facebook',
              icon: 'icon-bar-chart',
              page: '/Channel/facebook',
              show: 'true',
            },
          ]
        }
      },
      {
        title: 'Reports & Statistics',
        icon: 'feather ft-activity',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            //  {
            //   title: 'AI Agent Report',
            //   page: '/reports-statistics/ai-agent-report',
            //   show: 'true',
            // },
            {   
              title: 'Call Details Report',
              page: '/reports-statistics/call-details-report',
              show: 'true',
            },
            {
              title: 'Outbound Summary Report',
              page: '/reports-statistics/outbound-summary-report',
              show: 'true',
            },
            {
              title: 'Inbound Summary Report',
              page: '/reports-statistics/inbound-summary-report',
              show: 'true',
            },
            {
              title: 'Campaign Report',
              page: '/reports-statistics/campain-report',
              show: 'true',
            },
            // {
            //   title: 'Interactions History',
            //   page: '/recordingsmanager/under-dev'
            // },
            {
              title: 'Queue Report',
              page: '/reports-statistics/queue-report',
              show: 'true',
            },
            {
              title: 'Agent Activity Report',
              page: '/reports-statistics/agent-activity-report',
              show: 'true',
            },
            {
              title: 'Agent Disposition Report',
              page: '/reports-statistics/agent-disposition-report',
              show: 'true',
            },
            {
              title: 'Agent Summary Report',
              page: '/reports-statistics/agent-summary-report-final',
              show: 'true',
            },
            {
              title: 'Agent Performance Report',
              page: '/reports-statistics/agent-perfomance-report',
              show: 'true',
            },
            {
              title: 'Channel Utilization Report',
              page: '/reports-statistics/channel-utilization-report',
              show: 'true',
            },
            {
              title: 'Agent Sessions Report',
              page: '/reports-statistics/agent-session-report',
              show: 'false',
            },
            {
              title: 'DID Report',
              page: '/reports-statistics/did-reporting',
              show: 'true',

            },
            {
              title: 'Completed Call Backs Report',
              page: '/reports-statistics/completed-call-back-report',
              show: 'true',
            },
            {
              title: 'Scheduled Call Backs Report',
              page: '/reports-statistics/scheduled-call-back-report',
              show: 'true',
            },
            {
              title: 'Voice Broadcast Report',
              page: '/reports-statistics/voice-brodcast-report',
              show: 'true',
            },
            {
              title: 'Voice Bot Report',
              page: '/reports-statistics/voice-bot-report',
              show: 'true',
            },
            {
              title: 'SLA Abandoned Calls Report',
              page: '/reports-statistics/sla-abondoned-call-report',
              show: 'true',
            },
            {
              title: 'SLA Answered Calls Report',
              page: '/reports-statistics/sla-answered-call-reports',
              show: 'true',
            },
            {
              title: 'Interaction History Report',
              page: '/reports-statistics/interaction-history-report',
              show: 'true',
            },

            // {
            //   title: 'SMS Report',
            //   page: '/reports-statistics/sms-report',
            //   show: 'true',
            // },
            {
              title: 'IVR Report',
              page: '/reports-statistics/ivr-report',
              show: 'true',
            },
            {
              title: 'Custom Reports',
              page: '/reports-statistics/scheduled-report-list',
              show: 'true',
            },
            {
              title: 'Twitter Report',
              page: '/reports-statistics/twitter-report',
              show: 'true',
            },
            {
              title: 'Telegram Report',
              page: '/reports-statistics/telegram-report',
              show: 'true',
            },
            {
              title: 'Facebook Report',
              page: '/reports-statistics/facebook-report',
              show: 'true',
            },
            {
              title: 'Whatsapp Report',
              page: '/reports-statistics/whatsapp-report',
              show: 'true',
            },
            {
              title: 'WhatsApp Broadcast Report',
              page: '/reports-statistics/whatsapp-broadcast-report',
              show: 'true',
            },
            {
              title: 'Email Broadcast Report',
              page: '/reports-statistics/email-report',
              show: 'true',
            },
            {
              title: 'Email Campaign Report',
              page: '/reports-statistics/inbound-email-report',
              show: 'true',
            },
            {
              title: 'Social Disposition Report',
              page: '/reports-statistics/Social-disposition-report',
              show: 'true',
            },                   
            {
              title: 'Web Chat Report',
              page: '/reports-statistics/web-chat-report',
              show: 'true',
            },
            {
              title: 'Script Report',
              page: '/reports-statistics/script-report',
              show: 'true',
            },
            {
              title: 'Internal Chat Report',
              page: '/reports-statistics/internal-chat-report',
              show: 'true',
            },
            {
              title: 'Viber Report',
              page: '/reports-statistics/viber-report',
              show: 'true',
            },
            {
              title: 'Order Report',
              page: '/reports-statistics/order-report',
              show: 'true',
            },
          ]
        },
      },
      {
        title: 'Audits',
        icon: 'la-balance-scale',
        page: '/qualitymanager/quality-list',
        show: 'true',
      },
    ]
  },
  TENANT_MENU_ITEMS: {
    items: []
  },
  AGENT_MENU_ITEMS: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Account Dashboard',
              icon: 'la-home',
              page: '/dashboard',
              show: 'true',
            },
            {
              title: 'Live Dashboard',
              icon: 'icon-bar-chart',
              page: '/tenant/live-dashboard',
              show: 'true',
            },
            {
              title: 'Wallboard',
              icon: 'icon-paper-plane',
              page: '/tenant/wallboard',
              show: 'true',
            },
          ]
        }
      },
      {
        title: 'Recording',
        icon: 'la-headphones',
        page: '/Channel/recordings',
        show: 'true',
      },
      {
        title: 'Audits',
        icon: 'la-balance-scale',
        page: '/qualitymanager/quality-list',
        show: 'true',
      },
    ]
  },

  SUPERVISOR_MENU_ITEMS: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        show: 'true',
        submenu: {
          items: [
            {
              title: 'Account Dashboard',
              icon: 'la-home',
              page: '/dashboard',
              show: 'true',
            },
            {
              title: 'Live Dashboard',
              icon: 'icon-bar-chart',
              page: '/tenant/live-dashboard',
              show: 'true',
            },
            {
              title: 'Wallboard',
              icon: 'icon-paper-plane',
              page: '/tenant/wallboard',
              show: 'true',
            },
          ]
        }
      },
      {
        title: 'Recording',
        icon: 'la-headphones',
        page: '/Channel/recordings',
        show: 'true',
      },
      {
        title: 'Audits',
        icon: 'la-balance-scale',
        page: '/qualitymanager/quality-list',
        show: 'true',
      },
    ]
  },
  
};

