import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class APIService {

    API_URL = environment.API_URL;
    AGENT_QUEUE_URL = environment.AGENT_QUEUE_URL;
    TELEGRAM_BASE_URL = environment.TELEGRAM_BASE_URL;
    VIBER_BASE_URL = environment.VIBER_BASE_URL;
    SOCIAL_MEDIA_URL = environment.SOCIAL_MEDIA_URL;
    TWITTER_BASE_URL = environment.TWITTER_BASE_URL;
    WHATSAPP_BASE_URL = environment.WHATSAPP_BASE_URL;
    FACEBOOK_BASE_URL = environment.FACEBOOK_BASE_URL;

    constructor(
        private httpClient: HttpClient
    ) { }

    login(data) {
        return this.httpClient.post(`${this.API_URL}account/login-qa/`, data);
    }
    refreshToken(data:any){
        return this.httpClient.post(`${this.API_URL}refreshtoken`, data);
    }
    availablebreakstier(params){
        return this.httpClient.post(`${this.API_URL}show-available-breaks-tier`,params);
    }
    uploadProfilePic(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}upload-profile-pic`, data);
    }
    removeProfilePic(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}remove-profile-pic`, data);
    }
    getAgentRole(data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-roles-by-role-uuid/${data}`);
    }
    updatePassword(data) {
        return this.httpClient.post(`${this.API_URL}tenant/update_password/`, data);
    }
    change_password(data) {
        return this.httpClient.post(`${this.API_URL}tenant/change_password`, data);
    }
    forgetPassword(data) {
        return this.httpClient.post(`${this.API_URL}tenant/forgot_password`, data);
    }
    otpVerify(data) {
        return this.httpClient.post(`${this.API_URL}tenant/verify_otp`, data);
    }
    resendOtp(data) {
        return this.httpClient.post(`${this.API_URL}tenant/resend_otp`, data);
    }
    changePassword(data, module) {
        return this.httpClient.post(`${this.API_URL}tenant/change_password/${data}`, module);
    }
    getSystemModuleCount() {
        return this.httpClient.get<any>(`${this.API_URL}admin/summary`);
    }
    updateTanent(updatedTanentData, tenant_id) {
        return this.httpClient.post(`${this.API_URL}tenant/update_tenant/${tenant_id}`, updatedTanentData);
    }
    deleteTenant(tenent_id) {
        return this.httpClient.delete(`${this.API_URL}tenant_delete_tenant/${tenent_id}`);
    }
    addAgent(agentData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-agent/`, agentData);
    }
    showAvailableAgent(tierData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-only-available-agent/`, tierData);
    }
    showAgentDetailByQueuqUUID(requestData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agents-in-camp/`, requestData);
    }
    deleteTiers(tierData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-tiers/`, tierData);
    }
    createTiers(tierData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-tiers/`, tierData);
    }
    deleteAgent(agent_id) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-agent-details-by-uuid/`, agent_id);
    }
    showAgentList(requestData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-list/`, requestData);
    }
    updateAgent(agentUpdatedData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-agent-details-by-uuid/`, agentUpdatedData);
    }
    getAgentById(queueUUID) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-details-by-uuid/`, { call_center_agent_uuid: queueUUID });
    }
    createQueue(queueData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-queue/`, queueData);
    }
    listQueues(tenantid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-queue-list/`, tenantid);
    }
    getQueueDetailByUuid(queue_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-queue-details-by-uuid/`, { call_center_queue_uuid: queue_uuid });
    }
    editQueueByuuId(queue_updated_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-queue-details-by-uuid/`, queue_updated_data);
    }
    editExtension(extension_updated_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-extension/`, extension_updated_data);
    }
    getExtensionList(tanent_id) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-extensions-list/`, tanent_id);
    }
    onlyAvailableExtensionList(tanent_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-only-available-extensions/`, tanent_data);
    }
    ticketList(doamin_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-ticket-list/`, doamin_uuid);
    }
    deleteTicket(deleted_ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-ticket/`, deleted_ticket_data);
    }
    getTicketComment(request_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/get-comments-on-ticket/`, request_data);
    }
    getBreakList(tenant_data) {
        return this.httpClient.post(`${this.API_URL}getBreakList`, tenant_data);
    }
    applyBreak(break_applied_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-agent-status/`, break_applied_data);
    }
    requestBreak(agent_data) {
        return this.httpClient.post(`${this.API_URL}requestBreak`, agent_data);
    }
    applyAgentBreak(agent_data) {
        return this.httpClient.post(`${this.API_URL}assignAgentBreak`, agent_data);
    }
    checkAgentRequestedBreak(requested_data) {
        return this.httpClient.post(`${this.API_URL}checkAgentRequestedBreak`, requested_data);
    }
    getDispositionList(disposition_data) {
        return this.httpClient.get(`${this.API_URL}getDispositionList/${disposition_data}`);
    }
    getCampaignList(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getCampaignList`, tenant_data);
    }
    showAgentSessionMasterList(tenent_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-session-master-list/`, tenent_uuid);
    }
    sipTrunkList(requested_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-sip-trunk-list/`, requested_data);
    }
    modifyCustomer(modify_customer_id, updated_customer_data) {
        return this.httpClient.post(`${this.API_URL}CRM/update_CRM/${modify_customer_id}`, updated_customer_data);
    }

    searchCustomer(searched_customer) {
        return this.httpClient.get(`${this.API_URL}search_crm/${searched_customer}`);
    }
    createBulkCustomer(customer_data) {
        return this.httpClient.post(`${this.API_URL}import_crm_by_file`, customer_data);
    }
    getListOfCRM(id: string): Observable<any> {
        return this.httpClient.get(`${this.API_URL}show-all-crms/${id}`);
    }
    getCRMById(id: string): Observable<any> {
        return this.httpClient.get(`${this.API_URL}CRM/get-crm-by-uuid/${id}`);
    }
    getSocial(url: string): Observable<any> {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}${url}`);
    }
    postAgent(url: string, params): Observable<any> {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}${url}`, params);
    }
    getCrmDumpByCamppUuid(camp_uuid) {
        return this.httpClient.get(`${this.API_URL}CRM/get-crm-details-by-camp-uuid/${camp_uuid}`);
    }
    getCrmDumpByGroupUuid(group_uuid) {
        return this.httpClient.get(`${this.API_URL}CRM/get-crm-details-by-group-uuid/${group_uuid}`);
    }
    getCreatedCustomerData(domain_uuid) {
        return this.httpClient.get(`${this.API_URL}CRM/get-customer-database-details-by-domain-uuid/${domain_uuid}`);
    }
    putAgent(url: string, params): Observable<any> {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}${url}`, params);
    }
    getAgent(url: string): Observable<any> {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}${url}`);
    }
    getScriptDump(camp_uuid) {
        return this.httpClient.get(`${this.API_URL}getScriptDumpDetailByCampUuid/${camp_uuid}`);
    }
    agentFeedbackDetail(script_id) {
        return this.httpClient.get(`${this.API_URL}get-agent-feedback-details-by-script_id/${script_id}`);
    }
    getCampaignOutcome(param) {
        return this.httpClient.post(`${this.API_URL}getOutComeCallDetailsByCampUuid`, param);
    }
    tenantDashBoardData(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-dashboard-data/`, tenant_data);
    }
    agentDashBoardData(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-calls-dashboard-data/`, tenant_data);
    }
    showPromptList(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-prompt-list/`, tenant_data);
    }
    resetJobStatus(job_reset_status_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}reset-job-status/ `, job_reset_status_data);
    }
    importGroupDataByJobId(import_job_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}import-group-data-by-job-id`, import_job_data);
    }
    addGroup(group_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addGroup`, group_data);
    }
    editGroup(group_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}editGroup`, group_data);
    }
    getGroupList(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getGroupList`, tenant_data);
    }
    getGroupContacts(group_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getGrpDetailById/${group_uuid}`);
    }
    deleteGroup(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteGroupById`, params);
    }
    addGroupWithFile(group_numbers_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addGroupWithFile`, group_numbers_data);
    }
    getTanentCompletedCall(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-tanent-completed-call/${tenant_data}`);
    }
    getTanentTotalCall(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-tanent-total-call/${tenant_data}`);
    }
    getTanentAbandonedCall(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-tanent-abandoned-call/${tenant_data}`);
    }
    tenantInBoundOutBoundCall(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-inbound-outbound-total-call/${tenant_data}`);
    }
    getAgentCallStaticsList(agent_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getCallStaticsList/${agent_data}`);
    }
    getAgentCompletedCall(agent_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-completed-call/${agent_data}`);
    }
    getAgentTotalCall(agent_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-total-call/${agent_data}`);
    }
    getAgentAbandonedCall(agent_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-abandoned-call/${agent_data}`);
    }
    getIvrList(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getIvrList/${tenant_data}`);
    }
    getallAppUserId(tenent_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}getall_appUserId/${tenent_uuid}`);
    }
    updateSocailPlateform(id, tenant_uuid, updated_configuration_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update_smoochConfiguration/${id}/${tenant_uuid}`, updated_configuration_data);
    }
    getTenantList() {
        return this.httpClient.get<any>(`${this.API_URL}getall_tenant/`);
    }
    getAgentDetailsLive(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-team-tier-details-by-uuid/`, params);
    }
    disableChatUser(chat_user_id) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}disable_appUser/${chat_user_id}`);
    }
    showAllCallForTenant(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-all-calls-for-tanent/`, tenant_data);
    }
    showQueueCalls(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-queue-calls/`, tenant_data);
    }
    showAgentCalls(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-calls/`, tenant_data);
    }
    showAllCallsOfAgent(agent_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-all-calls-for-an-agent/`, agent_data);
    }
    voiceBotReport(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voicebot-report`, param);
    }
    getVoicebotConf(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}/tenant/get-voicebot-configuration`, tenant_data);
    }
    getVoiceBotById(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voicebot-by-id`, params);
    }

    getVoicebotSummary(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voicebot-summary`, params);
    }

    voicebotConversation(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voicebot-conversation-history`, params);
    }

    getScriptList(tenant_data) {
        return this.httpClient.post(`${this.API_URL}getScriptList`, tenant_data);
    }
    deleteScriptById(script_id) {
        return this.httpClient.delete(`${this.API_URL}deleteScriptById/${script_id}`);
    }
    updateScriptForm(params) {
        return this.httpClient.post(`${this.API_URL}addScriptDump`, params);
    }
    agentSupervisorCount(params) {
        return this.httpClient.post(`${this.API_URL}tenant/agent-dashboard/`, params);
    }
    displayTableColumns(params) {
        return this.httpClient.post(`${this.API_URL}tenant/agent-dashboard/`, params);
    }
    getScriptForm(params) {
        return this.httpClient.post(`${this.API_URL}getScriptDumpDetailById`, params);
    }
    uploadFile(params) {
        return this.httpClient.post(`${this.API_URL}uploadScriptGallery`, params);
    }
    getFiles(id: string) {
        return this.httpClient.get(`${this.API_URL}getScriptFileListByUuid/${id}`);
    }
    getScriptDetailsById(script_id) {
        return this.httpClient.get(`${this.API_URL}getScriptDetailById/${script_id}`);
    }
    updateScript(script_data) {
        return this.httpClient.put(`${this.API_URL}updateScriptById`, script_data);
    }
    getAvailableCampaignForScript(tenant_uuid) {
        return this.httpClient.get(`${this.API_URL}getAvailableCampaignNotWithScript/${tenant_uuid}`);
    }
    updateTeam(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-supervisor-team/`, data);
    }
    createTeam(team_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-supervisor-team/`, team_data);
    }
    showTeams(tenant_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-supervisor-teams/`, tenant_uuid);
    }
    deleteTeam(team_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/remove-supervisor-team/`, team_data);
    }
    showTeamMembers(team_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-supervisor-team-tiers/`, team_data);
    }
    asigneSupervisorTeam(team_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/add-agent-to-supervisor-team/`, team_data);
    }
    removeTeamMember(remove_member_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/remove-agent-from-supervisor-team/`, remove_member_data);
    }
    supervisorTeamMember(supervisor_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-supervisor-team-for-supervisor/`, supervisor_data);
    }
    showAvailableSupervisorAgent(supervisor_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-available-supervisors-or-agents/`, supervisor_data);
    }
    getChatQueueList(queue_name) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_queue_list/${queue_name}`);
    }
    createSoicalQueue(chat_queue_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}create_queue`, chat_queue_data);
    }
    asigneAgentToChatQueue(agent_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}assign_agent_to_queue`, agent_data);
    }
    agentsInSocialQueue(queue_info) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_assigned_agent_list/${queue_info}`);
    }
    removeAgentFromChatQueue(agent_info) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}remove_agent_from_queue/${agent_info}`);
    }
    deleteChatQueue(queue_info) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-chatqueue`, queue_info);
    }
    updateChatQueue(queue_uuid, updated_queue_info) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update_queue/${queue_uuid}`, updated_queue_info);
    }
    addOutComeGroup(outcome_group_data) {
        return this.httpClient.post(`${this.API_URL}addOutComeGroupByDomainUuid`, outcome_group_data);
    }
    addOutComeAttributes(outcome_attribute_data) {
        return this.httpClient.post(`${this.API_URL}addAttributesByDomainUuid`, outcome_attribute_data);
    }
    getOutComeAttributes(tenant_id) {
        return this.httpClient.get(`${this.API_URL}getOutComeAttributesByDomainUuid/${tenant_id}`);
    }
    outcomeGroupList(tenent_domain_uuid) {
        return this.httpClient.get(`${this.API_URL}getOutComeGroupListByDomainUuid/${tenent_domain_uuid}`);
    }
    deleteOutcomeGroup(group_uuid) {
        return this.httpClient.post(`${this.API_URL}deleteOutComeGroupByID`, group_uuid);
    }
    createOutcome(outcome_data) {
        return this.httpClient.post(`${this.API_URL}createOutcomeByDomainUuid`, outcome_data);
    }
    getOutComeListByUuid(params) {
        return this.httpClient.post(`${this.API_URL}getOutComeListByUuid`, params);
    }
    deleteOutComeAttribute(attribute_id) {
        return this.httpClient.post(`${this.API_URL}deleteOutComeAttributeByID`, attribute_id);
    }
    deleteOutComeByID(outcome_id) {
        return this.httpClient.post(`${this.API_URL}deleteOutComeByID`, outcome_id);
    }
    saveOutComeDetails(outcome_data) {
        return this.httpClient.post(`${this.API_URL}saveOutComeDetailsByAgentUuid`, outcome_data);
    }
    getAsignedCampaignsToAgent(fetch_camp_params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}campWithQueues`, fetch_camp_params);
    }
    mapGrpWithFile(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}mapGroupWithFile`, params);
    }
    getAgentWithCampaign(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}campWithTiers`, params);
    }
    availableQueues(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}queue-for-camp/${domain_uuid}`);
    }
    getCampaignActivity(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getCampaign`, param);
    }
    getTransferAgentList(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-transfer-call-data`, param);
    }
    updateOutComeDetails(outcome_data) {
        return this.httpClient.post(`${this.API_URL}updateOutComeDetailsByAgentUuid`, outcome_data);
    }
    getSystemOutcomeDataForCallBack(camp_uuid) {
        return this.httpClient.get(`${this.API_URL}getSystemOutComeCauseDetailsByCampUuid/${camp_uuid}`);
    }
    availableAgentForChatQueue(domain_uuid, queue_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}available_agent_list/${domain_uuid}/${queue_uuid}`);
    }
    loginInSocialPage(client_id, client_secret, flac_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}uvoice-facebook-login/${client_id}/${client_secret}/${flac_uuid}`);
    }
    getAgentAssignedFacebook(agent_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}show-agent-facebook-application/${agent_uuid}`);
    }
    asigneAgentToFacebookCampaign(agent_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}assign-agent-to-facebook-application`, agent_data);
    }
    agentsInConfiguration(flac_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}agent-list-assigned-to-facebook-application/${flac_uuid}`);
    }
    agentNotAssignedInApplication(flac_uuid, domain_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}agent-list-not-in-facebook-application/${flac_uuid}/${domain_uuid}`);
    }
    removeAssignedAgentFromAccount(agent_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}remove-agent-from-facebook-application/${agent_uuid}`);
    }
    updateFacebookAccoundConfiguration(domain_uuid, flac_uuid, updated_fb_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update-facebook-application/${flac_uuid}/${domain_uuid}`, updated_fb_account_data);
    }
    updateFacebookAccoundwall(domain_uuid, flac_uuid, updated_fb_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update-facebook-application/${flac_uuid}/${domain_uuid}`, updated_fb_account_data);
    }
    getPageAccessTokenByAccessToken(accessToken) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_page_id/${accessToken}`);
    }
    getPagePostByPageId(pageId, accessToken) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}getall_post_of_page/${pageId}/${accessToken}`);
    }
    getCommentPostByPageIdAndAccessToken(pagePostId, accessToken) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_comments_of_post/${pagePostId}/${accessToken}`);
    }
    addCommentOnPost(postCommentArg) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}comment_on_post`, postCommentArg);
    }
    likeDislikePostORCommentById(postCommentArg) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}likes_and_unlike_post_and_comments`, postCommentArg);
    }
    getAgentCampaignWrapUpTime(campaign_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getAgentCampaignTierAtCall/${campaign_uuid}`);
    }
    getSimpleReports(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-simple-reports/`, params);
    }
    addWallboardTemplate(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}AddWallboard`, params);
    }
    getWallboardTemplate(params) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}GetWallboardList?DomainUUID=${params}`);
    }
    deleteWallboardTemplate(params) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}DeleteWallboardByID/${params}`);
    }
    getSmotchCustomerByAgentUuid(agent_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}getcustomerbyagent_uuid/${agent_uuid}`);
    }
    getOutcomeTemplateList(domain_uuid) {
        return this.httpClient.get(`${this.API_URL}getOutComeListByDomainUuid/${domain_uuid}`);
    }
    updateOutComeTemplate(template_name) {
        return this.httpClient.post(`${this.API_URL}updateOutComeByDomainUuid`, template_name);
    }
    getSmsCampaigns(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getSmsCampaignList?domainUUID=${domain_uuid}`);
    }
    addSmsCampaign(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addSmsCampaign`, campaign_data);
    }
    deleteSmsCampaign(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteSmsCampaignByID`, params);
    }
    updateSmsCampaign(update_camp_date) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}updateSmsCampaign`, update_camp_date);
    }
    getSmsApisList(tenant_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getSmsGatewayList?domainUUID=${tenant_uuid}`);
    }
    deleteSmsGateway(gateway_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteSmsGatewayByID`, gateway_uuid);
    }
    addSmsApis(sms_api_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addSmsGateway`, sms_api_data);
    }
    updateSmsApis(updated_sms_api_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}updateSmsGateway`, updated_sms_api_data);
    }
    getSmsCampaignHistory(tenant_uuid, camp_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getSmsCampaignHistoryList?domainUUID=${tenant_uuid}&campUUID=${camp_uuid}`);
    }
    getEmailCamapigns(tenant_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}email-campaigns?domain_uuid=${tenant_uuid}`);
    }
    createEmailCamapign(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-campaign`, campaign_data);
    }
    deleteMailCampaign(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-email-campaign`, params);
    }
    updateEmailCamapign(campaign_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}email-campaign`, campaign_data);
    }
    saveTwitterPostAccount(twitter_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}save_twitter_auth`, twitter_account_data);
    }
    saveTwitterWallPost(twitter_account_data) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}save_twitter_auth`, twitter_account_data);
    }
    getTwitterPostAccount(tenant_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_twitter_auth_list/${tenant_uuid}`);
    }
    getTwitterWallPost(tenant_uuid) {
        return this.httpClient.get(`${this.TWITTER_BASE_URL}get_twitter_auth_list/${tenant_uuid}`);
    }
    deleteTwitterAccount(id, tenant_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete_twitter_auth/${id}/${tenant_uuid}`);
    }
    deleteTwitterWallPost(id, tenant_uuid) {
        return this.httpClient.delete(`${this.TWITTER_BASE_URL}delete_twitter_auth/${id}/${tenant_uuid}`);
    }
    updateTwitterAccount(id, tenant_uuid, updated_twitter_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update_twitter_auth/${id}/${tenant_uuid}`, updated_twitter_account_data);
    }
    updateTwitterWallPost(updated_twitter_account_data) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}update_twitter_auth`, updated_twitter_account_data);
    }
    updateLeadContactTableOptions(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}leadmanagement`, params);
    }
    updateLeadContactTable(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}leadmanagement`, params);
    }
    getTwitterTimeline(params) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}twitter_apis`, params);
    }
    getSingleTweet(params) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}twitter_apis`, params);
    }
    getTweetLikes(params) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}twitter_apis`, params);
    }
    recordingsList(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getRecordings/`, params);
    }
    getTrunkPrefix(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getCampaign`, params);
    }
    updateMailDetails(updated_smtp_config) {
        return this.httpClient.put(`${this.API_URL}updateMailDetails`, updated_smtp_config);
    }
    deleteMailDetails(params) {
        return this.httpClient.post(`${this.API_URL}deleteMailDetails`, params);
    }
    getCallBackList(domain_uuid, camp_uuid) {
        return this.httpClient.get(`${this.API_URL}getCallbackList/${domain_uuid}/${camp_uuid}`);
    }
    addCustomerToBlackList(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add-block-number`, params);
    }
    getBackListNumber(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}block-numbers/${domain_uuid}`);
    }
    daleteBackListNumber(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-block-number`, params);
    }
    getQueueMapWithCampaign(domain_uuid, camp_uuid, agent_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getReadyQueuesFromCampaign/${domain_uuid}/${camp_uuid}/${agent_uuid}`);
    }
    scheduleCallback(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}schedule-callback`, params);
    }
    dalateScheduledCallbackList(callback_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}delete-scheduled-callback/${callback_uuid}`);
    }
    searchCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/search-calls/`, params);
    }
    addQualityFeedback(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}call-quality/tenant`, params);
    }
    updateQualityFeedback(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}call-quality/tenant`, params);
    }
    getQualityFeedback(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}quality-agent-audits-with-status`, params);
    }
    deleteQualityFeedback(call_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}call-quality/${call_uuid}`);
    }
    agentFeedback(agent_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}call-quality/agent/${agent_uuid}`);
    }
    updateAuditByAgent(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}call-quality/agent`, params);
    }
    activeCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}active-calls`, params);
    }
    campaignCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-camp-calls/`, params);
    }

    getTenantModuleCount(param) {
        return this.httpClient.post(`${this.API_URL}tenant/summary/`, param);
    }
    getCampaignListByType(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getCampaignListByType`, params);
    }

    rolespermissionList(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-roles-and-permissions-by-domain-uuid/${domain_uuid}`);
    }
    deleteRolesandPermission(roles_permission_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}roles-and-permissions//${roles_permission_uuid}`);
    }
    UpdateRolesandPermission(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}roles-and-permissions`, params);
    }
    GetAdminSupportTicket() {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getall_admin/`);
    }
    createDatabaseManagement(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create/databasemanagement`, params);
    }
    GetdbData() {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get/databasemanagement`);
    }
    getCampaignDisposition(camp_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getDispositionDatasByCampUUID/${camp_uuid}`);
    }
    contactSearch(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}GetContactListByKeyName`, params);
    }

    storeDispositionOutcome(request_data) {
        return this.httpClient.post(`${this.API_URL}store-disposition-outcome`, request_data);
    }
    getCommentOfPost(page_post_id, token) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_comments_of_post/${page_post_id}/${token}`);
    }
    getLikeOfPost(page_post_id, token) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_likes_of_post/${page_post_id}/${token}`);
    }
    storeColumns(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}store-columns`, params);
    }
    showStoreColumns(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}show-store-columns`, params);
    }
    emailBlastingReport(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-report/`, param);
    }
    liveActiveAgents(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}active-agents/`, params);
    }
    liveActiveCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}active-calls/`, params);
    }
    getLiveCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}live-calls/`, params);
    }
    getCallWithCallTypes(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-call-with-call-types/`, params);
    }
    getCallsInQueue(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-calls-in-queue/`, params);
    }
    getLiveCallWithCallTypes(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-live-call-with-call-types/`, params);
    }
    getLiveCallByMonthly(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-live-call-by-monthly/`, params);
    }
    getTopAgentByCall(params) {
        return this.httpClient.post(`${this.API_URL}tenant/agent-by-call/`, params);
    }
    getTopAgentByHandleTime(params) {
        return this.httpClient.post(`${this.API_URL}tenant/agent-by-hendle-time/`, params);
    }
    getTenantAssignedFeature(data) {
        return this.httpClient.get(`${this.API_URL}tenant/get-tenant-role-permission-by-domain-uuid/${data}`);
    }
    forceLogout(param) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-agent-current-status`, param);
    }
    InCallAgent(param) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-agent-current-status`, param);
    }
    getTenantCurrentAuthToken(params) {
        return this.httpClient.post(`${this.API_URL}tenant/get-auth-token-by-domain-name`, params);
    }
    checkAgentCurrentStatus(param) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-current-status/${param}`);
    }
    updateQueueStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-queue-status/`, params);
    }
    updateVoiceBroadcastStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-broadcasts-status`, params);
    }
    updateAdminStatus(params) {
        return this.httpClient.put(`${this.API_URL}admin-active-deactive`, params);
    }
    facebookCronSecond(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}contact_list`, params);
    }
    addConferenceNumberToCall(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}make-conference-call`, param);
    }
    removeActiveCalls(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}remove-active-calls/`, param);
    }
    socialDispositionReport(domain_uuid) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}social-disposition-report`, domain_uuid);
    }
    facebookReport(domain_uuid) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}facebook-report`, domain_uuid);
    }
    whatsappReport(domain_uuid) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}whatsapp-report`, domain_uuid);
    }
    ViberReport(domain_uuid) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}viber-report`, domain_uuid);
    }
    getViberHistoryData(param) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}getchathistory`, param);
    }

    getOrderHistoryData(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}order-history`, param);
    }
    webchatreport(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}webchat-report`, domain_uuid);
    }
    agentActivityReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-activity-report/`, params);
    }
    outboundSummaryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}outbound-summary-report/`, params);
    }
    callDetailsReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}call-detail-report/`, params);
    }
    CDRSummaryData(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}/ai/emotion-detection`, params);
    }
    CampaignSummaryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}camp-report/`, params);
    }
    scheduledCallbackList(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}scheduled-callbacks/${domain_uuid}`);
    }
    ScheduledCallReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}schedule-call-report/`, params);
    }
    QueueReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}queue-report/`, params);
    }
    ivrReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ivr-report/`, params);
    }
    smsReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sms-report/`, params);
    }
    didReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}did-summary-report/`, params);
    }
    orderReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}order-report`, params);
    }
    agentDispositionReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-disposition-report/`, params);
    }
    agentPerfomanceReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-perfomance-report/`, params);
    }
    agentSessionReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-session-report/`, params);
    }
    agentSummaryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-summary-report/`, params);
    }
    completedCallbackReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}completed-calls-report/`, params);
    }
    slaAnsweredReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sla-answered-report/`, params);
    }
    slaAbandonedReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sla-abandoned-report/`, params);
    }
    voiceBroadcastReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voice-broadcast-report/`, params);
    }
    inboundSummaryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}Inbound-summary-report/`, params);
    }
    getHistoryData(param) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}getchathistory`, param);
    }
    getAllDid(tenant_id) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-did-list/`, tenant_id);
    }
    getEmailBroadcast(tenant_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}email-campaigns?domain_uuid=${tenant_uuid}`);
    }
    getsocialcampaign(param) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getsocialcampaign/${param}`);
    }
    userProductivityReport(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}user-productivity-report/`, domain_uuid);
    }
    inboundEmailHistory(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}inbound-email-report-history`, data);
    }
    emailCampaignReport(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}inbound-email-report`, param);
    }
    getEmailCampaigns(tenant_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-email-campaigns`, { domain_uuid: tenant_uuid });
    }
    interactionHisoryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}interaction-report/`, params);
    }
    telegramReport(domain_uuid) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}telegram-report`, domain_uuid);
    }
    internalchatReport(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}internalchat-report`, domain_uuid);
    }
    getinternalhistorychatReport(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}internalchat-history`, domain_uuid);
    }
    scheduledReports(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}scheduled-report/${domain_uuid}`);
    }
    scheduleNewReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}scheduled-report`, params);
    }
    updateReportSchedule(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}scheduled-report`, params);
    }
    deleteScheduledReport(domain_uuid, Scheduled_report_id) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}scheduled-report/${domain_uuid}/${Scheduled_report_id}`);
    }
    voicemailReports(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voice-mailsearch`, params);
    }
    // \\\\\\ Script report \\\\\\
    scriptreport(param) {
        return this.httpClient.post(`${this.API_URL}script-report`, param);
    }
    scriptreportHistory(param) {
        return this.httpClient.post(`${this.API_URL}script-fill-details`, param);
    }
    getWebchatcampaign(chat_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-web-chat-campaign/${chat_data}`);
    }
    getVoiceBroadcast(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}voice-broadcasts/${domain_uuid}`);
    }
    // WebChat configuration
    addWebchatConfig(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-webchat-configuration`, chat_data);
    }
    getWebchatConfig(chat_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-webchat-configuration/${chat_data}`);
    }
    deleteWebchatConfig(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-webchat-configuration`, chat_data);
    }
    updateWebchatConfig(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}edit-webchat-configuration`, chat_data);
    }
    updateWebchatStatusConfig(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-web-chat-confstatus`, chat_data);
    }





     // Telegram API end here
    // whatsapp apis start here 
    getWhatsappHistoryData(param) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}getchathistory`, param);
    }
    getTelegramHistoryData(param) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}getchathistory`, param);
    }
    addWhatsappConfiguration(whatsapp_configuration_data) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}add_whatsappConfiguration`, whatsapp_configuration_data);
    }
    getWhatsappConfiguration(tenant_uuid) {
        return this.httpClient.get(`${this.WHATSAPP_BASE_URL}get_whatsappConfiguration/${tenant_uuid}`);
    }
    deleteWhatsappConfiguration(id, tenant_uuid) {
        return this.httpClient.delete(`${this.WHATSAPP_BASE_URL}delete_whatsappConfiguration/${id}/${tenant_uuid}`);
    }
    updateWhatsAppConfiguration(whatsapp_id, tenant_uuid, updated_whatsapp_configuration_data) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}update_whatsappConfiguration/${whatsapp_id}/${tenant_uuid}`, updated_whatsapp_configuration_data);
    }
    getWhatsAppContacts(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}contact_lists`, params);
    }
    getwhatsappMessages(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get_messages`, params);
    }
    replyWhatsappMessage(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}reply_message`, params);
    }
    closeWhatsappChat(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}close_chat`, params);
    }
    sendNewWhatsappChat(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}send_template`, params);
    }
    FillDispositionForWhatsapp(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}fill_disposition`, params);
    }
    getWhatsappBroadcasts(domain_uuid) {
        return this.httpClient.get(`${this.WHATSAPP_BASE_URL}get-whatsapp-campaigns?domain_uuid=${domain_uuid}`);
    }
    createWhatsappBroadcast(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}create-whatsapp-campaign`, params);
    }
    updateWhatsappBroadcast(params) {
        return this.httpClient.put(`${this.WHATSAPP_BASE_URL}update-whatsapp-campaign`, params);
    }
    deleteWhatsappBroadcast(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}delete-whatsapp-campaign`, params);
    }
    whatsappBroadcastStatus(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}status-whatsapp-campaign`, params);
    }
    createWhatsappTemplate(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}save-template`, params);
    }
    updateWhatsapptemplate(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}update-template`, params);
    }
    deleteWhatsappTemplate(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}delete-template`, params);
    }
    getTemplateWhatsapp(domain_uuid){
        return this.httpClient.get(`${this.WHATSAPP_BASE_URL}get-template/${domain_uuid}`);
    }
    createWhatsappBot(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}add-chatbot-case`, params);
    }
    updateWhatsappBot(params) {
        return this.httpClient.put(`${this.WHATSAPP_BASE_URL}update-chatbot-case`, params);
    }
    getWhatsappBot(domain_uuid){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-chatbot-case`,domain_uuid);
    }
    deleteWhatsappBot(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}delete-chatbot-case`, params);
    }
    createWhatsappBotSubscription(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}add-bot`,param)
    }
    updateWhatsappBotSubscription(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}update-bot`,param)
    }
    getWhatsappBotSubscription(){
        return this.httpClient.get(`${this.WHATSAPP_BASE_URL}get-bot`)
    }
    deleteWhatsappBotSubscription(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}delete-Bot`,param)
    }
    StatusUpdateOFWhatsappBotSubscription(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}status-update`,param)
    }
    whatsappBroadcastReport(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}whatsapp-broadcast-report`,param)
    }
    getTenantListForWhatsappBot(){
        return this.httpClient.get(`${this.API_URL}getTenantList`)
    }
    requestReport(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}report_request`, param);
    }
    getwebchathistory(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-webchat-history`, chat_data);
    }
    socialMediaLogout(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}deassign_agent`, params);
    }
    // whatsapp apis ends here
    getTotalContactInContactList(list_info) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getTotalLead/${list_info}`);
    }

        // Email Dashboard start
        getEmailDashboardData(params) {
            return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-dashboard`, params);
        }
    
        // Blended Dashboard start
        blendedDashboardAgentStatus(data) {
            return this.httpClient.post(`${this.AGENT_QUEUE_URL}campaign-agent-status`, data);
        }
        blendedDashboardDetails(data){
            return this.httpClient.post(`${this.AGENT_QUEUE_URL}blended-campaign-dashboard`, data);
        }
        // Blended Dashboard end
        
        emailDashboardData(data){
            return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-dashboard`, data);
        }
        mediaDashBoardData(data) {
            return this.httpClient.post(`${this.WHATSAPP_BASE_URL}social-media-dashboard`, data);
        }
    
        QueueForMediaDashboard(data){
            return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-chats-in-queue`, data);
        }
        LiveChatForMediaDashboard(data){
            return this.httpClient.post(`${this.WHATSAPP_BASE_URL}live-chats`, data);
        }
        getMediaAgentDetailsLive(data){
            return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-agent-in-campaign-team`, data);
        }
        getLiveChats(params) {
            return this.httpClient.post(`${this.WHATSAPP_BASE_URL}live-chats`, params);
        }
        getChatsInQueue(params) {
            return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-chats-in-queue`, params);
        }
        // Social Media dashboard end 
        getAllCampaignList(uuid) {
            return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-all-campaigns-by-type`, uuid);
        }

        //    AI Agent
    Aiagentreport(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ai/ai-agent/v1/report`,params);
    }
    AIAgentConversation(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ai/ai-agent/v1/conversation-history`,params);
    }
    getAiAgentContext(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ai/ai-agent/v1/get-context`, tenant_data);
    }

    // Channel Audits

    whatsappAudits(params) { 
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}whatsapp-qa-report`, params);
    }

    voiceAudits(params) { 
        return  this.httpClient.post(`${this.AGENT_QUEUE_URL}getRecordings-qa-agent`, params);
    }

    emailAudits(params) { 
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}inbound-email-qa-report`, params);
    }

    viberAudits(params) { 
        return this.httpClient.post(`${this.VIBER_BASE_URL}viber-qa-report`, params);
    }

    telegramAudits(params) { 
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}telegram-qa-report`, params);
    }

    facebookAudits(params) { 
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}facebook-qa-report`, params);
    }

   /******************************************* QA Dashboard ************************************************************/

    qualityAgentDashboard(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}quality-agent-dashboard`, params);
    }

    qualityAverageRating(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}quality-agent-average-rating`, params);
    }

    topTenAgentByRating(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}top-ten-agent-by-rating`, params);
    }
   
    auditPendingByChannel(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}audit-pending-by-channel`, params);
    }

}

export { environment };
