import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import {Subject} from 'rxjs';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  data = new Subject();
  private messageSource = new BehaviorSubject<string>("");
  currentMessage = this.messageSource.asObservable();
  agentReadyStateSubject = new Subject();
  callTerminateSubject = new Subject();
  userLoginType = new Subject();
  ref = firebase.firestore().collection('users');

  constructor(private firestore: AngularFirestore) {
  }

  getUsers() {
    return this.firestore.collection('users').snapshotChanges();
  }

  getCurrentUser(userId): Observable<any> {
    return this.firestore.collection('users', ref => ref.where('uid', '==', userId)).snapshotChanges();
  }

  createUser(user) {
    return this.ref.add(user);
  }
    // for profile pic
    changeMessage(message: string) {
      this.messageSource.next(message)
    }

}
