import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { QaDashboardComponent } from './qa-dashboard/qa-dashboard.component';

const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'forget-password', component: ForgetPasswordComponent },
      { path: 'otp-verification', component: OtpVerificationComponent },
      { path: 'change-password', component: ChangePasswordComponent },

      { path: '', component: LoginComponent }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      // { path: 'dashboard', component: ChangelogComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', component: QaDashboardComponent, canActivate: [AuthGuard] },
      {
        path: 'qualitymanager', loadChildren: () => import('../app/content/qualitymanager/qualitymanager.module').then(m => m.QualitymanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'Channel', loadChildren: () => import('../app/content/recordingsmanager/recordingsmanager.module').then(m => m.RecordingsmanagerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reports-statistics', loadChildren: () => import('../app/content/reportsandstatistics/reportsandstatistics.module').then(m => m.ReportsandstatisticsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile', loadChildren: () => import('../app/content/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tenant', loadChildren: () => import('../app/content/tenants/tenants.module').then(m => m.TenantsModule),
        canActivate: [AuthGuard]
      },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
